import {
  useMeeting,
  useParticipant,
  usePubSub,
} from "@videosdk.live/react-sdk";
import { MoreVert, SearchOutlined } from "@mui/icons-material";
import {
  Avatar,
  Box,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
  useTheme,
  Tooltip,
  MenuList,
  MenuItem,
  Popover,
  Button,
} from "@mui/material";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { appThemes, useMeetingAppContext } from "../../MeetingAppContextDef";
import { RaiseHand } from "../../icons";
import { List } from "react-virtualized";
import useWindowSize from "../../utils/useWindowSize";
import useIsTab from "../../utils/useIsTab";
import useIsMobile from "../../utils/useIsMobile";
import ConfirmBox from "../../components/ConfirmBox";
import { nameTructed } from "../../utils/common";
import ToggleModeContainer from "../../components/ToggleModeContainer";
import { meetingModes } from "../../CONSTS";
import ParticipantScreenShareIcon from "../../icons/ParticipantScreenShareIcon";
import ParticipantMicOnIcon from "../../icons/ParticipantMicOnIcon";
import ParticipantMicOffIcon from "../../icons/ParticipantMicOffIcon";
import ParticipantVideoOnIcon from "../../icons/ParticipantVideoOnIcon";
import ParticipantVideoOffIcon from "../../icons/ParticipantVideoOffIcon";
import ParticipantPinIcon from "../../icons/ParticipantPinIcon";
import ParticipantRemoveIcon from "../../icons/ParticipantRemoveIcon";
import useIsHls from "../useIsHls";
import useCustomTrack from "../../utils/useCustomTrack";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { outlinedInputClasses } from "@mui/material/OutlinedInput";
import ConfigTabPanel from "./ConfigTabPanel";
import { useAsyncError, useLocation } from "react-router-dom";
import PersonAddAlt1RoundedIcon from '@mui/icons-material/PersonAddAlt1Rounded';
import PersonRemoveAlt1RoundedIcon from '@mui/icons-material/PersonRemoveAlt1Rounded';
import { addNoParticipantStartTime, updateParticipantsEndTime } from "../../../Services/MeetingApi";
import RequestedEntries from "../../components/RequestedEntries";
import { border } from "@mui/system";

function ParticipantListItem({ raisedHand, participantId }) {
  const { presenterId } = useMeeting();
  const mMeeting = useMeeting();
  const {
    participant,
    micOn,
    webcamOn,
    displayName,
    isLocal,
    pinState,
    webcamStream,
    pin,
    unpin,
    enableMic,
    disableMic,
    enableWebcam,
    disableWebcam,
  } = useParticipant(participantId);

  const {
    participantCanToggleOtherMic,
    participantCanToggleOtherWebcam,
    partcipantCanToogleOtherScreenShare,
    participantCanToggleOtherMode,
    canRemoveOtherParticipant,
    canPin,
    animationsEnabled,
    meetingMode,
    appTheme,
  } = useMeetingAppContext();

  const { getCustomVideoTrack, getCustomAudioTrack } = useCustomTrack();
  // const SampleMeetingView = () => {
  //   //Getting all the participants
  //   const { participants } = useMeeting();

  //   //Looping over the participants and rendering a simple box
  //   return (
  //     <div style={{ display: "grid", gridTemplateColumns: "repeat(3,1fr)" }}>
  //       {[...participants.keys()].map((participantId, index) => (
  //         <SampleParticipantView key={index} participantId={participantId} />
  //       ))}
  //     </div>
  //   );
  // };

  // const SampleParticipantView = ({ participantId }) => {
  //   const { displayName, micOn, webcamOn, webcamStream } =
  //     useParticipant(participantId);

  //   const webcamRef = useRef(null);
  //   useEffect(() => {
  //     if (webcamRef.current) {
  //       if (webcamOn && webcamStream) {
  //         const mediaStream = new MediaStream();
  //         mediaStream.addTrack(webcamStream.track);

  //         webcamRef.current.srcObject = mediaStream;
  //         webcamRef.current
  //           .play()
  //           .catch((error) =>
  //             console.error("videoElem.current.play() failed", error)
  //           );
  //       } else {
  //         webcamRef.current.srcObject = null;
  //       }
  //     }
  //   }, [webcamStream, webcamOn]);
  //   return (
  //     <div
  //       style={{
  //         height: "300px",
  //         background: "#C0C2C9",
  //       }}
  //     >
  //       <video width={"10%"} height={"10%"} ref={webcamRef} autoPlay />
  //     </div>
  //   );
  // };


  const isParticipantPresenting = useMemo(() => {
    return presenterId === participantId;
  }, [presenterId, participantId]);

  const { publish } = usePubSub(`SCR_SHR_REQ_${participantId}`);

  const [isParticipantKickoutVisible, setIsParticipantKickoutVisible] =
    useState(false);

  const [participantMode, setParticipantMode] = useState(null);
  const [moreIconClicked, setMoreIconClicked] = useState(null);

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const meetingId = queryParams.get("meetingId");

  const handleClick = (event) => {
    setMoreIconClicked(event.currentTarget);
  };

  const handleClose = () => {
    setMoreIconClicked(null);
  };

  const addToStage = () => {
    pin();

    setTimeout(async () => {
      console.log(mMeeting.pinnedParticipants.size);
      if (mMeeting?.isRecording && mMeeting.pinnedParticipants.size === 1) {
        const data = {
          MeetingID: meetingId,
          EndTime: getCurrentTimestamp()
        }
        await updateParticipantsEndTime(data);
      }
    }, 100); // Adjust the delay if necessary (100 ms here)
  }

  const removeFromStage = () => {
    unpin();

    setTimeout(async () => {

      console.log(mMeeting.pinnedParticipants.size);
      if (mMeeting?.isRecording && mMeeting.pinnedParticipants.size === 0) {
        const data = {
          MeetingID: meetingId,
          StartTime: getCurrentTimestamp()
        }
        await addNoParticipantStartTime(data);
      }
    }, 100); // Adjust the delay if necessary (100 ms here)
  }

  function getCurrentTimestamp() {
    const now = new Date();
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed
    const day = String(now.getDate()).padStart(2, '0');
    const hours = String(now.getHours()).padStart(2, '0');
    const minutes = String(now.getMinutes()).padStart(2, '0');
    const seconds = String(now.getSeconds()).padStart(2, '0');

    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  }

  const isHls = useIsHls();

  // const classes = useStyles();

  const theme = useTheme();

  usePubSub(`CURRENT_MODE_${participantId}`, {
    onMessageReceived: (data) => {
      setParticipantMode(data.message);
    },
    onOldMessagesReceived: (messages) => {
      const latestMessage = messages.sort((a, b) => {
        if (a.timestamp > b.timestamp) {
          return -1;
        }
        if (a.timestamp < b.timestamp) {
          return 1;
        }
        return 0;
      })[0];

      if (latestMessage) {
        setParticipantMode(latestMessage.message);
      }
    },
  });

  return (
    <Box
      mt={1}
      p={1}
      style={{
        backgroundColor:
          appTheme === appThemes.DARK
            ? theme.palette.darkTheme.seven
            : appTheme === appThemes.LIGHT
              ? theme.palette.lightTheme.three
              : theme.palette.common.sidePanel,
        borderRadius: 6,
      }}
    >
      <Box
        style={{
          display: "flex",
          flex: 1,
          justifyContent: "center",
          alignItems: "center",
          position: "relative",
        }}
      >
        <Avatar
          variant={"rounded"}
          style={{
            color: appTheme === appThemes.LIGHT && "white",
            backgroundColor:
              appTheme === appThemes.DARK
                ? theme.palette.darkTheme.five
                : appTheme === appThemes.LIGHT
                  ? theme.palette.lightTheme.five
                  : "",
          }}
        >
          {displayName?.charAt(0)}
        </Avatar>
        {/* <SampleMeetingView /> */}
        <Box ml={1} mr={0.5} style={{ flex: 1, display: "flex" }}>
          <Typography
            style={{
              textOverflow: "ellipsis",
              overflow: "hidden",
              whiteSpace: "nowrap",
              width: '100px',
              color:
                appTheme === appThemes.LIGHT
                  ? "#95959E"
                  : "white",
            }}
            variant="body1"
            noWrap
          >
            {isLocal ? "You" : nameTructed(displayName, 10)}
          </Typography>
        </Box>

        <Box
          style={{
            display: "flex",
            flex: 1,
            justifyContent: "center",
            alignItems: "center",
            position: "absolute",
            top: 0,
            right: 0,
            bottom: 0,
          }}
        >
          <Box
            style={{
              display: "flex",
              flex: 1,
              justifyContent: "center",
              alignItems: "center",
              // position: "relative",
            }}
          >
            <Box
              style={{
                display: "flex",
                flex: 1,
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {raisedHand && (
                <Box
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                  mr={0}
                  p={0}
                  ml={0.5}
                >
                  <RaiseHand
                    fillColor={
                      appTheme === appThemes.LIGHT
                        ? "#95959E"
                        : theme.palette.common.white
                    }
                  />
                </Box>
              )}
              {canPin && (
                <Box
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                  ml={1}
                  mr={0}
                  p={0.5}
                >
                  <Tooltip
                    title={pinState?.share || pinState?.cam ? "Remove" : `Add to Stage`}
                  >
                    <IconButton
                      disabled={
                        // !expanded ||
                        meetingMode === meetingModes.VIEWER ||
                        participantMode === meetingModes.VIEWER
                      }
                      size="small"
                      onClick={(e) => {
                        e.stopPropagation();
                        pinState?.share || pinState?.cam ? removeFromStage() : addToStage();
                      }}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      {pinState?.share || pinState?.cam ? (
                        // If share or cam is true, show "Add to stage" button
                        <PersonRemoveAlt1RoundedIcon color="error" />
                        // <Button variant="contained" color="error">
                        //   <span className="fs-small text-trans-none">Remove</span>
                        // </Button>
                      ) : (
                        // If share and cam are both false, show "Remove" button
                        // <Button variant="contained" color="primary">
                        //   <span className="fs-small text-trans-none">Add to Stage</span>
                        // </Button>
                        <PersonAddAlt1RoundedIcon color="primary" />
                      )}
                    </IconButton>
                  </Tooltip>
                </Box>
              )}
              {meetingMode === meetingModes.CONFERENCE && (
                <Box ml={0.5} mr={0.5}>
                  <IconButton
                    disabled={
                      !participantCanToggleOtherMic ||
                      isLocal ||
                      meetingMode === meetingModes.VIEWER ||
                      participantMode === meetingModes.VIEWER
                    }
                    style={{ padding: 0 }}
                    onClick={async () => {
                      if (micOn) {
                        disableMic();
                      } else {
                        const track = await getCustomAudioTrack();
                        enableMic(track);
                      }
                    }}
                  >
                    <Box
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        borderRadius: 100,
                      }}
                      p={0.5}
                    >
                      {micOn ? (
                        <ParticipantMicOnIcon
                          fillColor={
                            appTheme === appThemes.LIGHT
                              ? "#95959E"
                              : "white"
                          }
                        />
                      ) : (
                        <ParticipantMicOffIcon />
                      )}
                    </Box>
                  </IconButton>
                </Box>
              )}
              {meetingMode === meetingModes.CONFERENCE && (
                <Box ml={1} mr={0}>
                  <IconButton
                    disabled={
                      !participantCanToggleOtherWebcam ||
                      isLocal ||
                      meetingMode === meetingModes.VIEWER ||
                      participantMode === meetingModes.VIEWER
                    }
                    style={{ padding: 0 }}
                    onClick={async () => {
                      if (webcamOn) {
                        disableWebcam();
                      } else {
                        const track = await getCustomVideoTrack();
                        enableWebcam(track);
                      }
                    }}
                  >
                    <Box
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        borderRadius: 100,
                      }}
                      p={0.5}
                    >
                      {webcamOn ? (
                        <ParticipantVideoOnIcon
                          fillColor={
                            appTheme === appThemes.LIGHT
                              ? "#95959E"
                              : "white"
                          }
                        />
                      ) : (
                        <ParticipantVideoOffIcon />
                      )}
                    </Box>
                  </IconButton>
                </Box>
              )}
            </Box>
          </Box>

          {meetingMode === meetingModes.CONFERENCE && (
            <Box
              style={{
                transition: `all ${200 * (animationsEnabled ? 1 : 0.5)}ms`,
                marginLeft: '8px',
              }}
            >
              <IconButton
                style={{ padding: 0 }}
                disabled={meetingMode === meetingModes.VIEWER}
                onClick={(e) => {
                  handleClick(e);
                }}
              >
                <Box
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    borderRadius: 100,
                  }}
                  p={0.5}
                >
                  <MoreVert
                    fontSize="small"
                    style={{
                      height: 18,
                      width: 18,
                      color:
                        appTheme === appThemes.LIGHT
                          ? "#95959E"
                          : "white",
                    }}
                  />
                </Box>
              </IconButton>
              <Popover
                open={Boolean(moreIconClicked)}
                anchorEl={moreIconClicked}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                style={{ marginTop: 4, marginRight: 16 }}
              >
                <MenuList
                  style={{
                    backgroundColor:
                      appTheme === appThemes.DARK
                        ? theme.palette.darkTheme.slightLighter
                        : appTheme === appThemes.LIGHT
                          ? theme.palette.lightTheme.two
                          : "",
                    color:
                      appTheme === appThemes.DARK
                        ? theme.palette.common.white
                        : appTheme === appThemes.LIGHT
                          ? "#95959E"
                          : "",
                  }}
                >
                  {!isLocal && canRemoveOtherParticipant && (
                    <>
                      <MenuItem
                        key={`remove`}
                        onClick={(e) => {
                          e.stopPropagation();
                          setIsParticipantKickoutVisible(true);
                          handleClose();
                        }}
                      >
                        <Box style={{ display: "flex", flexDirection: "row" }}>
                          <Box
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            <ParticipantRemoveIcon
                              fillColor={
                                appTheme === appThemes.LIGHT
                                  ? "#95959E"
                                  : theme.palette.common.white
                              }
                            />
                          </Box>
                          <Box
                            style={{
                              display: "flex",
                              flex: 1,
                              flexDirection: "column",
                              marginLeft: 12,
                              justifyContent: "center",
                            }}
                          >
                            <Typography
                              style={{
                                fontSize: 14,
                                color:
                                  appTheme === appThemes.LIGHT &&
                                  "#95959E",
                              }}
                            >
                              Remove Participant
                            </Typography>
                          </Box>
                        </Box>
                      </MenuItem>
                    </>
                  )}
                  {participantCanToggleOtherMode && isHls && (
                    <ToggleModeContainer
                      participantId={participantId}
                      participantMode={participantMode}
                      handleClose={handleClose}
                    />
                  )}
                  {meetingMode === meetingModes.CONFERENCE && (
                    <MenuItem
                      key={`screen-share`}
                      onClick={(e) => {
                        e.stopPropagation();
                        publish({
                          setScreenShareOn: !isParticipantPresenting,
                        });
                        handleClose();
                      }}
                      disabled={
                        !(
                          !isLocal &&
                          partcipantCanToogleOtherScreenShare &&
                          (presenterId ? isParticipantPresenting : true)
                        ) ||
                        meetingMode === meetingModes.VIEWER ||
                        participantMode === meetingModes.VIEWER
                      }
                    >
                      <Box style={{ display: "flex", flexDirection: "row" }}>
                        <Box
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          {isParticipantPresenting ? (
                            <ParticipantScreenShareIcon
                              fillColor={
                                appTheme === appThemes.LIGHT
                                  ? "#95959E"
                                  : theme.palette.common.white
                              }
                            />
                          ) : (
                            <ParticipantScreenShareIcon
                              fillColor={
                                appTheme === appThemes.LIGHT
                                  ? "#95959E"
                                  : !(
                                    !isLocal &&
                                    partcipantCanToogleOtherScreenShare &&
                                    (presenterId
                                      ? isParticipantPresenting
                                      : true)
                                  ) ||
                                    meetingMode === meetingModes.VIEWER ||
                                    participantMode === meetingModes.VIEWER
                                    ? appTheme === appThemes.LIGHT
                                      ? "#95959E"
                                      : "#ffffff80"
                                    : appTheme === appThemes.LIGHT
                                      ? "#95959E"
                                      : "#ffffff"
                              }
                            />
                          )}
                        </Box>
                        <Box
                          style={{
                            display: "flex",
                            flex: 1,
                            flexDirection: "column",
                            marginLeft: 12,
                            justifyContent: "center",
                          }}
                        >
                          <Typography
                            style={{
                              fontSize: 14,
                              color:
                                !(
                                  !isLocal &&
                                  partcipantCanToogleOtherScreenShare &&
                                  (presenterId ? isParticipantPresenting : true)
                                ) ||
                                  meetingMode === meetingModes.VIEWER ||
                                  participantMode === meetingModes.VIEWER
                                  ? theme.palette.text.secondary
                                  : appTheme === appThemes.LIGHT
                                    ? "#95959E"
                                    : theme.palette.common.white,
                            }}
                          >
                            {isParticipantPresenting
                              ? "Stop Screenshare"
                              : "Request Screenshare"}
                          </Typography>
                        </Box>
                      </Box>
                    </MenuItem>
                  )}
                </MenuList>
              </Popover>
            </Box>
          )}
        </Box>
      </Box>
      <ConfirmBox
        open={isParticipantKickoutVisible}
        title={`Remove ${nameTructed(displayName, 15)} `}
        subTitle={`Are you sure want to remove ${nameTructed(
          displayName,
          15
        )} from the call?`}
        successText={"Remove"}
        rejectText={"Cancel"}
        onSuccess={() => {
          participant.remove();
          setIsParticipantKickoutVisible(false);
        }}
        onReject={() => {
          setIsParticipantKickoutVisible(false);
        }}
      />
    </Box>
  );
}

export default function ParticipantsTabPanel({ panelWidth, panelHeight }) {
  const [filterQuery, setFilterQuery] = useState("");

  const { participants } = useMeeting();
  const { raisedHandsParticipants, appTheme } = useMeetingAppContext();

  const sortedRaisedHandsParticipants = useMemo(() => {
    const participantIds = [...participants.keys()];

    const notRaised = participantIds.filter(
      (pID) =>
        raisedHandsParticipants.findIndex(
          ({ participantId: rPID }) => rPID === pID
        ) === -1
    );

    const raisedSorted = raisedHandsParticipants.sort((a, b) => {
      if (a.raisedHandOn > b.raisedHandOn) {
        return -1;
      }
      if (a.raisedHandOn < b.raisedHandOn) {
        return 1;
      }
      return 0;
    });

    const combined = [
      ...raisedSorted.map(({ participantId: p }) => ({
        raisedHand: true,
        participantId: p,
      })),
      ...notRaised.map((p) => ({ raisedHand: false, participantId: p })),
    ];

    return combined;
  }, [raisedHandsParticipants, participants]);

  const theme = useTheme();

  const filterParticipants = (
    sortedRaisedHandsParticipants,
    participants,
    filterQuery
  ) =>
    filterQuery?.length > 3
      ? sortedRaisedHandsParticipants.filter(({ participantId }) => {
        const { displayName } = participants.get(participantId);

        const hide = !displayName
          ?.toLowerCase()
          .includes(filterQuery.toLowerCase());

        return !hide;
      })
      : sortedRaisedHandsParticipants;

  const part = useMemo(
    () =>
      filterParticipants(
        sortedRaisedHandsParticipants,
        participants,
        filterQuery
      ),

    [sortedRaisedHandsParticipants, participants, filterQuery]
  );

  function rowRenderer({ key, index, isScrolling, isVisible, style }) {
    const { participantId, raisedHand } = part[index];

    return (
      <div {...{ key, style }}>
        {isVisible ? (
          <ParticipantListItem
            participantId={participantId}
            raisedHand={raisedHand}
          />
        ) : (
          <Box
            mt={1}
            style={{
              height: 56,
              backgroundColor:
                appTheme === appThemes.DARK
                  ? theme.palette.darkTheme.seven
                  : appTheme === appThemes.LIGHT
                    ? theme.palette.lightTheme.three
                    : theme.palette.common.sidePanel,
              borderRadius: 6,
            }}
          ></Box>
        )}
      </div>
    );
  }

  const { width } = useWindowSize();
  const isTab = useIsTab();
  const isMobile = useIsMobile();
  const outerTheme = useTheme();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const guest = queryParams.get("guest");

  const customTheme = (outerTheme) =>
    createTheme({
      palette: {
        mode: outerTheme.palette.mode,
      },
      components: {
        MuiTextField: {
          styleOverrides: {
            root: {
              "--TextField-brandBorderColor": "#70707033",
              "--TextField-brandBorderHoverColor": "#70707033",
              "--TextField-brandBorderFocusedColor": "#70707033",
              "& label.Mui-focused": {
                color: "var(--TextField-brandBorderFocusedColor)",
              },
            },
          },
        },
        MuiOutlinedInput: {
          styleOverrides: {
            notchedOutline: {
              borderColor: appTheme !== appThemes.LIGHT && "#404B53",
            },
            root: {
              [`&:hover .${outlinedInputClasses.notchedOutline}`]: {
                borderColor:
                  appTheme === appThemes.LIGHT
                    ? "var(--TextField-brandBorderHoverColor)"
                    : "white",
              },
              [`&.Mui-focused .${outlinedInputClasses.notchedOutline}`]: {
                borderColor:
                  appTheme === appThemes.LIGHT
                    ? "var(--TextField-brandBorderFocusedColor)"
                    : "white",
                borderWidth: "1px",
              },
              color: appTheme === appThemes.LIGHT ? "#404B53" : "white",
            },
          },
        },
      },
    });

  return (
    <Box
      p={1}
      style={{
        height: panelHeight - 32,
        overflowY: "auto",
        overflowX: "hidden",
      }}
    >
      <Box>
        <ThemeProvider theme={customTheme(outerTheme)}>
          <TextField
            variant="outlined"
            fullWidth
            placeholder="Search Participants"
            style={{
              color:
                appTheme === appThemes.LIGHT &&
                "#95959E",
              borderRadius: "6px",
            }}
            onChange={(e) => setFilterQuery(e.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchOutlined
                    style={{
                      color:
                        appTheme === appThemes.LIGHT
                          ? "#95959E"
                          : "white",
                    }}
                  />
                </InputAdornment>
              ),
            }}
          />
        </ThemeProvider>
      </Box>
      <Box style={{ overflowY: "auto", maxHeight: '46vh' }}>
        <List
          width={isTab || isMobile ? width - 32 : panelWidth - 36}
          height={panelHeight - 90}
          rowCount={part.length}
          rowHeight={64}
          rowRenderer={rowRenderer}
        />
      </Box>
      <span style={{
        color: 'white',
        fontWeight: '600'
      }}>Participants Queue</span>
      <RequestedEntries />
      {guest !== 'true' &&
        <ConfigTabPanel />
      }
    </Box>
  );
}
