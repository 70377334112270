import {
  Box,
  Button,
  Dialog,
  FormControl,
  Grid,
  IconButton,
  MenuItem,
  Select,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import React, { useEffect, useRef, useState } from "react";
import useResponsiveSize from "../../utils/useResponsiveSize";
import useWindowSize from "../../utils/useWindowSize";
import ConfirmBox from "../ConfirmBox";
import { appThemes } from "../../MeetingAppContextDef";
import { useTranslation } from "react-i18next";
import { styled, ThemeProvider, createTheme } from "@mui/material/styles";

const CustomMenuItem = styled(MenuItem)`
  &:hover {
    background: #2b303499;
  }
  &.Mui-selected {
    background: #3f4045 !important;
  }
  &.Mui-selected:hover {
    background: #3f4045;
  }
`;

const CustomLightMenuItem = styled(MenuItem)`
  &:hover {
    background: #ccd2d899;
  }
  &.Mui-selected {
    background: #d3d7da !important;
  }
  &.Mui-selected:hover {
    background: #d3d7da;
  }
  &.MuiMenuItem-root {
    color: #404B53;
  }
`;

const CustomDeafultMenuItem = styled(MenuItem)`
  &:hover {
    background: #43425399;
  }
  &.Mui-selected {
    background: #545362 !important;
  }
  &.Mui-selected:hover {
    background: #545362;
  }
`;

const AudioAnalyser = ({ audioTrack }) => {
  const theme = useTheme();
  const audioTrackRef = useRef();
  const audioAnalyserIntervalRef = useRef();

  const [volume, setVolume] = useState(null);

  const analyseAudio = (audioTrack) => {
    const audioStream = new MediaStream([audioTrack]);
    const audioContext = new AudioContext();

    const audioSource = audioContext.createMediaStreamSource(audioStream);
    const analyser = audioContext.createAnalyser();

    analyser.fftSize = 512;
    analyser.minDecibels = -127;
    analyser.maxDecibels = 0;
    analyser.smoothingTimeConstant = 0.4;

    audioSource.connect(analyser);

    const volumes = new Uint8Array(analyser.frequencyBinCount);
    const volumeCallback = () => {
      analyser.getByteFrequencyData(volumes);

      const volumeSum = volumes.reduce((sum, vol) => sum + vol);
      const averageVolume = volumeSum / volumes.length;

      setVolume(averageVolume);
    };

    audioAnalyserIntervalRef.current = setInterval(volumeCallback, 100);
  };

  const stopAudioAnalyse = () => {
    clearInterval(audioAnalyserIntervalRef.current);
  };

  useEffect(() => {
    audioTrackRef.current = audioTrack;

    if (audioTrack) {
      analyseAudio(audioTrack);
    } else {
      stopAudioAnalyse();
    }
  }, [audioTrack]);

  return (
    <Box
      style={{
        width: 80,
        height: 100,
        position: "relative",
      }}
    >
      {[
        {
          borderBottomLeftRadius: 0,
          borderBottomRightRadius: 0,
          borderTopLeftRadius: 100,
          borderTopRightRadius: 100,
          top: 0,
          alignItems: "flex-end",
        },
        {
          borderBottomLeftRadius: 100,
          borderBottomRightRadius: 100,
          borderTopLeftRadius: 0,
          borderTopRightRadius: 0,
          top: "50%",
          alignItems: "flex-start",
        },
      ].map(
        (
          {
            alignItems,
            top,
            borderBottomLeftRadius,
            borderBottomRightRadius,
            borderTopLeftRadius,
            borderTopRightRadius,
          },
          i
        ) => (
          <Box
            key={`audio_analyzer_i_${i}`}
            style={{
              height: "50%",
              display: "flex",
              justifyContent: "space-evenly",
              alignItems,
              position: "absolute",
              top,
              left: 0,
              right: 0,
            }}
          >
            {[40, 70, 100, 100, 70, 40].map((height, j) => (
              <Box
                key={`audio_analyzer_j_${j}`}
                style={{
                  borderBottomLeftRadius,
                  borderBottomRightRadius,
                  borderTopLeftRadius,
                  borderTopRightRadius,
                  backgroundColor: theme.palette.primary.main,
                  width: 80 / 12,
                  height: `${(volume / 256) * height}%`,
                  transition: "all 50ms",
                  transitionTimingFunction: "ease-in",
                }}
              ></Box>
            ))}
          </Box>
        )
      )}
    </Box>
  );
};

const CustomizedSelect = styled(Select)`
  & .MuiSelect-icon {
    color: #404b53;
  }
`;

export default function SettingDialogueBox({
  open,
  onClose,
  popupVideoPlayerRef,
  webcams,
  mics,
  setting,
  setSetting,
  setSelectedMic,
  setSelectedWebcam,
  changeWebcam,
  changeMic,
  videoTrack,
  audioTrack,
  participantCanToggleSelfMic,
  participantCanToggleSelfWebcam,
  appTheme,
}) {
  const MenuElement =
    appTheme === appThemes.LIGHT
      ? CustomLightMenuItem
      : appTheme === appThemes.DARK
        ? CustomMenuItem
        : CustomDeafultMenuItem;

  const selectTheme = createTheme({
    components: {
      MuiSelect: {
        styleOverrides: {
          root: {
            color: "#fff",
          },
        },
      },
      MuiMenu: {
        styleOverrides: {
          paper: {
            background:
              appTheme === appThemes.LIGHT
                ? "#EFF0F2"
                : appTheme === appThemes.DARK
                  ? "#202124"
                  : "#333244",
            color: appTheme === appThemes.LIGHT ? "#404B53" : "#fff",
          },
        },
      },
      MuiMenuItem: {
        styleOverrides: {
          root: {
            color: appTheme === appThemes.LIGHT ? "#EFF0F2" : "#fff",
          },
        },
      },
    },
  });

  const { t } = useTranslation();
  const theme = useTheme();
  const isXStoSM = useMediaQuery(theme.breakpoints.between("xs", "sm"));

  const isXSOnly = useMediaQuery(theme.breakpoints.only("xs"));
  const isSMONly = useMediaQuery(theme.breakpoints.only("sm"));
  const [dlgDevices, setDlgDevices] = useState(false);

  const [boxHeight, setBoxHeight] = useState(0);
  const boxRef = useRef();

  const { width: windowWidth } = useWindowSize();

  useEffect(() => {
    if (boxRef.current && boxRef.current.offsetHeight !== boxHeight) {
      setBoxHeight(boxRef.current.offsetHeight);
    }
  }, [windowWidth]);

  const handleSetting = (event, n) => {
    setSetting(n);
  };

  const handleClose = () => {
    onClose();
  };

  const internalPadding = useResponsiveSize({
    xl: 3,
    lg: 5,
    md: 2,
    sm: 2,
    xs: 2,
  });

  return (
    <ThemeProvider theme={theme}>
      <Grid container>
        <Box style={{ width: 'inherit' }}>
          <>
            <FormControl style={{ width: "100%", marginTop: 8 }}>
              <ThemeProvider theme={selectTheme}>
                <CustomizedSelect
                  fullWidth
                  variant="outlined"
                  value={audioTrack?.getSettings()?.deviceId || ""}
                  style={{
                    border: `1px solid ${appTheme === appThemes.LIGHT
                        ? theme.palette.lightTheme.three
                        : "white"
                      }`,
                    color: appTheme === appThemes.LIGHT && "#95959E",
                  }}
                  onChange={(e) => {
                    changeMic(e.target.value);
                  }}
                  displayEmpty // This ensures the placeholder is shown when no item is selected
                >
                  <MenuElement value="" disabled>
                    {audioTrack?.getSettings()?.deviceId
                      ? "Select Microphone"
                      : "Mic not selected"}
                  </MenuElement>
                  {mics?.map((item, i) => {
                    return item?.kind === "audioinput" ? (
                      <MenuElement
                        key={i}
                        value={item?.deviceId}
                        onClick={(e) => {
                          setSelectedMic((s) => ({
                            ...s,
                            id: item?.deviceId,
                          }));
                        }}
                      >
                        {item?.label ? item.label : `Mic ${i + 1}`}
                      </MenuElement>
                    ) : null;
                  })}
                </CustomizedSelect>
              </ThemeProvider>
            </FormControl>

            <FormControl style={{ width: "100%", marginTop: 8 }}>
              <ThemeProvider theme={selectTheme}>
                <CustomizedSelect
                  fullWidth
                  variant="outlined"
                  value={videoTrack?.getSettings()?.deviceId || ""}
                  onChange={(e) => {
                    changeWebcam(e.target.value);
                  }}
                  style={{
                    border: `1px solid ${appTheme === appThemes.LIGHT
                        ? theme.palette.lightTheme.three
                        : "white"
                      }`,
                    color: appTheme === appThemes.LIGHT && "#95959E",
                  }}
                  displayEmpty // This ensures the placeholder is shown when no item is selected
                >
                  <MenuElement value="" disabled>
                    {videoTrack?.getSettings()?.deviceId
                      ? "Select Webcam"
                      : "Webcam not selected"}
                  </MenuElement>
                  {webcams?.map((item, i) => {
                    return item?.kind === "videoinput" ? (
                      <MenuElement
                        key={i}
                        value={item?.deviceId}
                        onClick={() => {
                          setSelectedWebcam((s) => ({
                            ...s,
                            id: item?.deviceId,
                          }));
                        }}
                      >
                        {item?.label ? item.label : `Webcam ${i + 1}`}
                      </MenuElement>
                    ) : null;
                  })}
                </CustomizedSelect>
              </ThemeProvider>
            </FormControl>
          </>

          <ConfirmBox
            open={dlgDevices}
            title="Mic or webcam not available"
            subTitle="Please connect a mic and webcam to speak and share your video in the meeting. You can also join without them."
            successText="DISMISS"
            onSuccess={() => {
              setDlgDevices(false);
            }}
          />
        </Box>
      </Grid>
    </ThemeProvider>
  );
}
