import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "bootstrap/dist/css/bootstrap.min.css";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ThemeProvider } from "@mui/material";
import generateMuiTheme from "./Meeting/mui/theme";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { Provider } from 'react-redux'
import store from './redux/store'

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <>
    <Provider store={store} >
      <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_OAUTH_CLIENT_ID}>
        <ThemeProvider theme={generateMuiTheme()}>
          <App/>
        </ThemeProvider>
      </GoogleOAuthProvider>
    </Provider>
  </>
);

reportWebVitals();
