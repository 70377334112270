import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { MeetingProvider, useMeeting } from '@videosdk.live/react-sdk';
import { meetingModes } from '../Meeting/CONSTS';
import PinnedLayoutViewContainer from '../Meeting/meetingContainer/pinnedLayoutViewContainer/PinnedLayoutViewContainer';
import TestPinnedLayoutViewContainer from '../Meeting/meetingContainer/pinnedLayoutViewContainer/TestPinnedLayoutViewContainer';
import TestPinnedLayoutViewContainerCopy from '../Meeting/meetingContainer/pinnedLayoutViewContainer/TestPinnedLayoutViewContainerCopy';
import { MeetingAppProvider } from '../Meeting/MeetingAppContextDef';

const CustomBanner = () => {
    const [isMeetingReady, setIsMeetingReady] = useState(false);
    const [meetingError, setMeetingError] = useState(null);
    const location = useLocation();

    // Get query parameters from URL
    const useQuery = () => {
        return new URLSearchParams(location.search);
    };

    const query = useQuery();
    const token = query.get('token');
    const meetingId = query.get('meetingId');
    const bannerName = query.get('bannerName') || 'Default Banner';
    const position = query.get('position') || 'bl';
    const backgroundColor = query.get('backgroundColor') || 'black';
    const color = query.get('color') || 'white';

    const bannerStyles = {
        position: 'absolute',
        top: position.includes('t') ? '0' : 'auto',
        bottom: position.includes('b') ? '0' : 'auto',
        left: position.includes('l') ? '0' : 'auto',
        right: position.includes('r') ? '0' : 'auto',
        transform: position === 'cc' ? 'translate(-50%, -50%)' : 'none',
        backgroundColor: backgroundColor,
        color: color,
        padding: '10px 20px',
        fontSize: '20px',
        textAlign: 'center',
        zIndex: 1000,
    };

    const meetingConfig = {
        meetingId: meetingId,
        micEnabled: false,
        webcamEnabled: false,
        name: "recorder",
        maxResolution: "hd",
        preferredProtocol: "UDP_ONLY",
        autoConsume: false,
        mode: meetingModes.CONFERENCE,
        multiStream: true,
    };

    // Error handling and meeting initialization
    useEffect(() => {
        if (meetingId && token) {
            // Initialize the meeting
            window.VideoSDK.config(token);
            const meeting = window.VideoSDK.initMeeting({
                meetingId: meetingId,
                name: "recorder",
                micEnabled: false,
                webcamEnabled: false,
            });

            meeting.on("meeting-joined", () => {
                console.log('Meeting joined successfully!');
                setIsMeetingReady(true);
            });

            meeting.on("error", (error) => {
                console.error("Error joining meeting:", error);
                setMeetingError(error);
            });

            meeting.join(); // Join the meeting
        } else {
            console.error("Missing token or meetingId in URL query parameters.");
        }
    }, [meetingId, token]);
    // const mMeeting = useMeeting();
    // console.log('nMeeting', mMeeting);

    // Loading or error state handling
    const paramKeys = new URLSearchParams(location.search);
    return (
        <div>
            <div style={bannerStyles}>{bannerName}</div>
            <MeetingAppProvider
                {...{
                    redirectOnLeave: paramKeys?.redirectOnLeave,
                    chatEnabled: paramKeys?.chatEnabled === "true",
                    screenShareEnabled: paramKeys?.screenShareEnabled === "true",
                    pollEnabled: paramKeys?.pollEnabled === "true",
                    whiteboardEnabled: paramKeys?.whiteboardEnabled === "true",
                    participantCanToggleSelfWebcam:
                        paramKeys?.participantCanToggleSelfWebcam === "true",
                    participantCanToggleSelfMic:
                        paramKeys?.participantCanToggleSelfMic === "true",
                    participantTabPanelEnabled:
                        paramKeys?.participantTabPanelEnabled === "true",
                    moreOptionsEnabled: paramKeys?.moreOptionsEnabled === "true",
                    // raiseHandEnabled: paramKeys?.raiseHandEnabled === "true",
                    canChangeLayout: paramKeys?.canChangeLayout === "true",
                    meetingLayoutTopic: paramKeys?.meetingLayoutTopic,
                    recordingEnabled: paramKeys?.recordingEnabled === "true",
                    realtimeTranscriptionEnabled:
                        paramKeys?.realtimeTranscriptionEnabled === "true",
                    hlsEnabled: paramKeys?.hlsEnabled === "true",
                    recordingWebhookUrl: paramKeys?.recordingWebhookUrl,
                    recordingAWSDirPath: paramKeys?.recordingAWSDirPath,
                    recordingTheme: paramKeys?.recordingTheme,
                    autoStartRecording: paramKeys?.autoStartRecording === "true",
                    autoStartHls: paramKeys?.autoStartHls === "true",
                    hlsPlayerControlsVisible:
                        paramKeys?.hlsPlayerControlsVisible === "true",
                    hlsTheme: paramKeys?.hlsTheme,
                    participantCanToggleRecording:
                        paramKeys?.participantCanToggleRecording === "true",
                    participantCanToggleRealtimeTranscription:
                        paramKeys?.participantCanToggleRealtimeTranscription === "true",
                    participantCanToggleHls:
                        paramKeys?.participantCanToggleHls === "true",
                    brandingEnabled: paramKeys?.brandingEnabled === "true",
                    poweredBy: paramKeys?.poweredBy === "true",
                    liveStreamEnabled: paramKeys?.liveStreamEnabled === "true",
                    autoStartLiveStream: paramKeys?.autoStartLiveStream === "true",
                    liveStreamOutputs: paramKeys?.liveStreamOutputs,
                    liveStreamTheme: paramKeys?.liveStreamTheme,
                    brandLogoURL:
                        paramKeys?.brandLogoURL?.length > 0
                            ? paramKeys?.brandLogoURL
                            : null,
                    brandName:
                        paramKeys?.brandName?.length > 0 ? paramKeys?.brandName : null,
                    waitingScreenImageUrl:
                        paramKeys?.waitingScreenImageUrl?.length > 0
                            ? paramKeys?.waitingScreenImageUrl
                            : null,
                    waitingScreenText:
                        paramKeys?.waitingScreenText?.length > 0
                            ? paramKeys?.waitingScreenText
                            : null,
                    participantCanLeave: paramKeys?.participantCanLeave !== "false",
                    askJoin: paramKeys?.askJoin === "true",
                    participantCanToggleOtherMic:
                        paramKeys?.participantCanToggleOtherMic === "true",
                    participantCanToggleOtherWebcam:
                        paramKeys?.participantCanToggleOtherWebcam === "true",
                    partcipantCanToogleOtherScreenShare:
                        paramKeys?.partcipantCanToogleOtherScreenShare === "true",
                    participantCanToggleLivestream:
                        paramKeys?.participantCanToggleLivestream === "true",
                    participantCanToggleOtherMode:
                        paramKeys?.participantCanToggleOtherMode === "true",
                    notificationSoundEnabled:
                        paramKeys?.notificationSoundEnabled === "true",
                    layoutType: paramKeys?.layoutType,
                    mode: paramKeys?.mode,
                    layoutPriority: paramKeys?.layoutPriority,
                    canPin: paramKeys?.canPin === "true",
                    canRemoveOtherParticipant:
                        paramKeys?.canRemoveOtherParticipant === "true",
                    participantCanEndMeeting:
                        paramKeys?.participantCanEndMeeting === "true",
                    canDrawOnWhiteboard: paramKeys?.canDrawOnWhiteboard === "true",
                    canToggleWhiteboard: paramKeys?.canToggleWhiteboard === "true",
                    canToggleVirtualBackground:
                        paramKeys?.canToggleVirtualBackground === "true",
                    canCreatePoll: paramKeys?.canCreatePoll === "true",
                    canToggleParticipantTab:
                        paramKeys?.canToggleParticipantTab === "true",
                    animationsEnabled: paramKeys?.animationsEnabled !== "false",
                    topbarEnabled: paramKeys?.topbarEnabled !== "false",
                    notificationAlertsEnabled:
                        paramKeys?.notificationAlertsEnabled !== "false",
                    participantNotificationAlertsEnabled: paramKeys?.participantNotificationAlertsEnabled !== "false",
                    debug: paramKeys?.debug === "true",
                    layoutGridSize: paramKeys?.layoutGridSize,
                    hideLocalParticipant: paramKeys?.hideLocalParticipant === "true",
                    alwaysShowOverlay: paramKeys?.alwaysShowOverlay === "true",
                    sideStackSize: paramKeys?.sideStackSize,
                    reduceEdgeSpacing: paramKeys?.reduceEdgeSpacing === "true",
                    isRecorder: paramKeys?.isRecorder === "true",
                    appTheme: paramKeys?.theme,
                    language: paramKeys?.language,
                    token: {token},
                    //
                    // recordingLayoutType: paramKeys?.recordingLayoutType,
                    // recordingLayoutPriority: paramKeys?.recordingLayoutPriority,
                    // recordingLayoutGridSize: paramKeys?.recordingLayoutGridSize,
                    // liveStreamLayoutType: paramKeys?.liveStreamLayoutType,
                    // liveStreamLayoutPriority: paramKeys?.liveStreamLayoutPriority,
                    // liveStreamLayoutGridSize: paramKeys?.liveStreamLayoutGridSize,
                    //
                    maintainVideoAspectRatio:
                        paramKeys?.maintainVideoAspectRatio === "true",
                    maintainLandscapeVideoAspectRatio:
                        paramKeys?.maintainLandscapeVideoAspectRatio === "true",
                    networkBarEnabled: paramKeys?.networkBarEnabled === "true",
                    cameraResolution: paramKeys?.cameraResolution,
                    cameraId: paramKeys?.cameraId,
                    cameraMultiStream: paramKeys?.cameraMultiStream === "true",
                    cameraOptimizationMode: paramKeys?.cameraOptimizationMode,
                    screenShareResolution: paramKeys?.screenShareResolution,
                    screenShareOptimizationMode: paramKeys?.screenShareOptimizationMode,
                    micQuality: paramKeys?.micQuality,
                    joinWithoutUserInteraction: paramKeys?.joinWithoutUserInteraction,
                    webcamEnabled: paramKeys?.webcamEnabled,
                    realtimeTranscriptionVisible:
                        paramKeys?.realtimeTranscriptionVisible === "true",
                }}
            >
                <MeetingProvider
                    config={{
                        meetingId: meetingId, // dynamically get this
                        micEnabled: false, // adjust as necessary
                        webcamEnabled: false, // adjust as necessary
                        name: "recorder",
                        maxResolution: "hd", // adjust as needed
                        preferredProtocol: "UDP_ONLY",
                        autoConsume: false,
                        multiStream: true,
                        mode: meetingModes.VIEWER,
                    }}
                    token={token}
                    joinWithoutUserInteraction
                    deviceInfo={{
                        sdkType: "prebuilt",
                        sdkVersion: "0.1.0", // adjust accordingly
                        rawUserAgent: null
                    }}
                >
                    {/* Your component that uses meeting functionality */}
                    <TestPinnedLayoutViewContainer
                        height="100vh"
                        width="100vw"
                        whiteboardToolbarWidth={48}
                        whiteboardSpacing={16}
                    />
                </MeetingProvider>
            </MeetingAppProvider>

        </div>
    );
};

export default CustomBanner;
