import React, { useState } from 'react';
import {  useParams } from "react-router-dom";
import { FaFacebook, FaLinkedin, FaSpotify, FaItunes, FaYoutube, FaInfoCircle } from 'react-icons/fa';
import './Pages.css';
import { authorize } from '../Services/SchedulesApi';
import { getShowDetails } from "../Services/ShowsApi";
import ErrorModal from '../Components/ErrorModal';

const parsedUrl = new URL(window.location.href);
const redirectUri = `${parsedUrl.protocol}//${parsedUrl.host}/li`;

const SocialMediaShare = () => {
  const [isHovered, setIsHovered] = useState(false);
  const [showMessage, setShowMessage] = useState(false);  // State for controlling the message visibility
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const { ShowID } = useParams();

  // Trigger OAuth2 authorization flow
  const handleYouTubeAuth = async () => {
    const response = await authorize();
    console.log("response::", response);
    if (response?.data?.body?.body) {
      localStorage.setItem('previousPage_yt', window.location.pathname);
      window.location.href = response.data.body.body;
    }
  };

  const handleLinkedinAuth = async () => {
    try {
      // Fetch the show details using ShowID
      const response = await getShowDetails(ShowID);
      console.log(response);
  
      // Check if YoutubeURL exists and is not null or an empty string
      const youtubeURL = response?.data?.Data?.YouTubeURL;
      if (youtubeURL && youtubeURL.trim() !== "") {
        const linkedInAuthURL = `https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=86shqxoly8bgso&redirect_uri=${redirectUri}&state=myposttestdata&scope=openid%20profile%20w_member_social%20email`;
        localStorage.setItem('previousPage_li', window.location.pathname);
        window.location.href = linkedInAuthURL;
      } else {
        setErrorMessage("Please make sure to upload your content to YouTube first before sharing it on LinkedIn.");
        setIsErrorModalOpen(true);
      }
    } catch (error) {
      console.error("Error fetching show details:", error);
      setErrorMessage("An error occurred please try again later.");
      setIsErrorModalOpen(true);
    }
  };

  const handleMouseEnter = () => {
    setIsHovered(true);
    setShowMessage(true);  // Show message when hovered
  };

  const handleMouseLeave = () => {
    setTimeout(() => {
      setIsHovered(false);
      setShowMessage(false); 
  }, 3000); 
  };

  return (
    <div className="social-media-share__section mt-3">
      <h2 className="social-media-share__heading">Share</h2>
      <div className="social-media-share__icons">
        <div
          className="social-media-share__icon-container"
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          <FaYoutube className="social-media-share__icon youtube" onClick={handleYouTubeAuth} />
        </div>
        <div className="social-media-share__icon-container">
          <FaLinkedin className="social-media-share__icon linkedin" onClick={handleLinkedinAuth} />
        </div>
        <div className="social-media-share__icon-container">
          <FaFacebook className="social-media-share__icon facebook" />
        </div>
        <div className="social-media-share__icon-container">
          <FaSpotify className="social-media-share__icon spotify" />
        </div>
        <div className="social-media-share__icon-container">
          <FaItunes className="social-media-share__icon itunes" />
        </div>
      </div>

      {showMessage && (
        <div className="social-media-share__hover-message">
          <span>
          Please create a YouTube channel before you share your content. For reference, you can follow this{" "}
            <a href="https://youtu.be/aKydtOXW8mI?feature=shared" target="_blank" rel="noopener noreferrer">
              video
            </a>
          </span>
        </div>
      )}
            <ErrorModal
                open={isErrorModalOpen}
                message={errorMessage}
                onClose={() => setIsErrorModalOpen(false)}
            />
    </div>
  );
};

export default SocialMediaShare;