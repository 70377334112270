import React from "react";
import { Dialog, DialogContent } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close"; // Close icon for the cross
import CheckCircleIcon from "@mui/icons-material/CheckCircle"; // Success checkmark icon

function SuccessModal({ open, message, onClose }) {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      PaperProps={{
        style: {
          backgroundColor: "#28a745", // Green background for success
          color: "white",
          zIndex: 50,
          maxWidth: '90vw',  // Allow modal to be responsive by limiting width to 90% of the screen width
        },
      }}
      style={{
        position: "fixed",
        inset: 0,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        zIndex: 5000,
      }}
    >
      <div
        style={{
            borderRadius: "10px",
            overflow: "hidden",
            width: "450px", // Slightly wider
            padding: "8px", // Additional padding for spacing
          }}
      >
        <DialogContent
          style={{
            padding: "20px", // Slightly increased padding
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <CheckCircleIcon
            fontSize="large"
            style={{
              marginRight: "12px", // Increased margin
              color: "white",
            }}
          />
          <p
            style={{
              color: "white",
              fontSize: "16px",
              margin: 0,
              padding: "0 5px",
              wordWrap: "break-word",  // Allow text to break onto new lines for long messages
            }}
          >
            {message}
          </p>
          <CloseIcon
            onClick={onClose}
            style={{
              cursor: "pointer",
              color: "white",
              position: "absolute",
              top: "10px",
              right: "10px",
            }}
          />
        </DialogContent>
      </div>
    </Dialog>
  );
}

export default SuccessModal;
