import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    x: 0,
    y: 0,
    logoFile: null,
}

const logoSlice = createSlice({
    name: 'logo',
    initialState,
    reducers: {
        setLogoFile(state, action) {
            state.logoFile = action.payload;
        },
        setLogoPosition(state, action) {
            state.x = action.payload.x;
            state.y = action.payload.y;
        }
    }
})
export const { setLogoFile, setLogoPosition } = logoSlice.actions;
export default logoSlice.reducer;