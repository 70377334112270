import React, { useEffect, useRef, useState, useCallback } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  IconButton,
  CircularProgress,
  Box,
  Snackbar,
  Alert,
} from "@mui/material";
import ReactPlayer from "react-player";
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
import {
  loadVideoFrames,
  trimVideo,
} from "../Services/ShowsApi";

const TrimModal = ({
  open,
  setTrimModalOpen,
  playerRef,
  showURL,
  isPlaying,
  onDuration,
  onProgress,
  startTime,
  setStartTime,
  endTime,
  setEndTime,
  duration,
  setDragging,
  roomID,
  mp4File,
  onVideoSaved,
  dragging,
  bucketName,
}) => {
  const [frames, setFrames] = useState([]);
  const [framesLoading, setFramesLoading] = useState(true);
  const canvasRef = useRef(null);
  const [previewTrimModalOpen, setPreviewTrimModalOpen] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);

  const extractFrames = useCallback(
    async (videoDuration) => {
      if (!showURL) return;

      setFramesLoading(true);
      setFrames([]);

      try {
        console.log("Fetching frames from backend API...");
        console.log("duraion", videoDuration);
        const inputBody = {
          object_key: mp4File.Key,
          bucketName: bucketName,
          type: "fetch",
        };
        console.log("input body: ", inputBody);
        // Replace with your backend API endpoint
        const response = await loadVideoFrames(inputBody);
        console.log("response: ", response);

        if (response.status === 200 && response.data.Data) {
          setFrames(response.data.Data); // Set frames from API response
          console.log("Extracted frames:", response.data.Data);
        } else {
          console.error("Failed to fetch frames: Invalid response format.");
        }
      } catch (error) {
        console.error("Error fetching frames from backend API:", error);
      } finally {
        setFramesLoading(false);
      }
    },
    [showURL]
  );

  const handleTrimModalClose = () => {
    setTrimModalOpen(false);
  };

  useEffect(() => {
    if (open && duration > 0) {
      extractFrames(duration); // Start frame extraction when modal opens
    }
  }, [open, duration, extractFrames]);

  // handles the trim video button
  const handleTrim = async () => {
    console.log(
      `Trimming video from ${parseInt(startTime)} to ${parseInt(endTime)}`
    );
    if (startTime < 0) {
      setStartTime(0);
    } else if (endTime > duration) {
      setEndTime(duration);
    }

    // Open the preview dialog and set it to loading state
    setPreviewTrimModalOpen(true);
  };

  const handleTrimPreviewClose = () => {
    setPreviewTrimModalOpen(false);
  };

  const handleSaveTrimmedVideo = async () => {
    try {
      const inputBody = {
        startTime: parseInt(startTime),
        endTime: parseInt(endTime),
        roomID,
        mp4file: mp4File,
        bucketName,
      };

      console.log("trim video input body: ", inputBody);
      const response = await trimVideo(inputBody);
      console.log("response: ", response);
      setPreviewTrimModalOpen(false);
      setTrimModalOpen(false);
      setSnackbarOpen(true);
    } catch (error) {
      console.error("Error during trim:", error);
    }
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleTrimModalClose}
        fullWidth
        maxWidth="md"
      >
        <DialogTitle>
          Trim Video
          <IconButton
            aria-label="close"
            onClick={handleTrimModalClose}
            style={{ position: "absolute", right: 16, top: 16 }}
          >
            <DeleteRoundedIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <canvas
            id="frameCanvas"
            ref={canvasRef}
            width={200} // Adjust width for frame extraction
            height={100} // Adjust height for frame extraction
            style={{ display: "none" }}
          ></canvas>

          <ReactPlayer
            ref={playerRef}
            url={showURL}
            playing={isPlaying}
            controls
            onDuration={onDuration}
            onProgress={onProgress}
            width="100%"
            height="100%"
          />

          <div
            className="frame-slider-container"
            style={{
              position: "relative",
              height: "100px",
              backgroundColor: "#2a2a2a",
              borderRadius: "10px",
              display: "flex",
              alignItems: "center",
              overflow: "hidden",
              marginTop: "16px",
            }}
          >
            <div
              className="frames-wrapper"
              style={{
                position: "relative",
                display: "flex",
                flex: 1,
                height: "100%",
                margin: "0 5px", // Add margin to create gaps for left and right
                overflow: "hidden",
              }}
            >
              {framesLoading ? (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "100%",
                    height: "100%",
                  }}
                >
                  <CircularProgress size={40} style={{ color: "#fff" }} />
                </Box>
              ) : (
                <div
                  className="frames-container"
                  style={{
                    display: "flex",
                    flex: 1,
                    gap: "1px", // Add small gaps between frames
                  }}
                >
                  {frames.map((frame, index) => (
                    <div
                      key={index}
                      className="frame"
                      style={{
                        backgroundImage: `url(${frame})`,
                        backgroundSize: "cover",
                        flex: 1,
                      }}
                    ></div>
                  ))}

                  {/* Highlight bar */}
                  <div
                    className="highlight-bar"
                    style={{
                      position: "absolute",
                      height: "100%",
                      left: `${Math.max(0, (startTime / duration) * 100)}%`,
                      width: `${Math.min(
                        ((endTime - startTime) / duration) * 100,
                        100 - (startTime / duration) * 100
                      )}%`,
                      backgroundColor: "rgba(255, 255, 255, 0.3)",
                    }}
                  ></div>

                  {/* Start Handle */}
                  <div
                    className="handle handle-start"
                    style={{
                      position: "absolute",
                      top: 0,
                      left: `${Math.max(0, (startTime / duration) * 100)}%`,
                      cursor: "ew-resize",
                      width: "10px",
                      height: "100%",
                      backgroundColor: "#00f",
                      zIndex: 10,
                    }}
                    onMouseDown={() => {
                      setDragging("start");
                      if (startTime >= endTime) {
                        setStartTime(endTime - duration * 0.01);
                      }
                      if (startTime < 0) {
                        setStartTime(0);
                      }
                    }}
                  ></div>

                  {/* End Handle */}
                  <div
                    className="handle handle-end"
                    style={{
                      position: "absolute",
                      top: 0,
                      left: `${Math.min((endTime / duration) * 100, 100)}%`,
                      cursor: "ew-resize",
                      width: "10px",
                      height: "100%",
                      backgroundColor: "#00f",
                      zIndex: 10,
                    }}
                    onMouseDown={() => {
                      setDragging("end");
                      if (endTime <= startTime) {
                        setEndTime(startTime + duration * 0.01);
                      }
                      if (endTime > duration) {
                        setEndTime(duration);
                      }
                    }}
                  ></div>
                </div>
              )}
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleTrimModalClose} color="primary">
            Close
          </Button>
          <Button
            onClick={() => {
              handleTrim();
            }}
            color="primary"
          >
            Trim
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={previewTrimModalOpen}
        onClose={handleTrimPreviewClose}
        fullWidth
        maxWidth="md"
      >
        <DialogTitle>Confirm to trim the selected video?</DialogTitle>
        <DialogActions>
          <Button onClick={handleTrimPreviewClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleSaveTrimmedVideo} color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>
      {/* Snackbar for showing success message */}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity="success"
          sx={{ width: "100%" }}
        >
          Video trimming is in process! please refresh after{" "}
          {parseInt(duration / 360) < 1 ? 1 : parseInt(duration / 360)} minutes.
        </Alert>
      </Snackbar>
    </div>
  );
};

export { TrimModal };
