import {
  Box,
  Button,
  Card,
  IconButton,
  Tabs,
  TextField,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import { useEffect, useRef, useState } from "react";
import LinesEllipsis from "react-lines-ellipsis";
import useCopyClipboard from "react-use-clipboard";
import { CopyIcon } from "../../icons";
import useWindowSize from "../../utils/useWindowSize";
import { appThemes } from "../../MeetingAppContextDef";
import { useTranslation } from "react-i18next";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { outlinedInputClasses } from '@mui/material/OutlinedInput';
import { useLocation } from "react-router-dom";
import Tab from '@mui/material/Tab';
import { getScheduledPodcastsByMeetingId } from "../../../Services/SchedulesApi";

export default function MeetingDetailModal({
  internalPadding,
  // name,
  setName,
  nameErr,
  setNameErr,
  startMeeting,
  meetingTitle,
  meetingUrl,
  appTheme,
}) {

  const customTheme = (outerTheme) =>
    createTheme({
      palette: {
        mode: outerTheme.palette.mode,
      },
      components: {
        MuiTextField: {
          styleOverrides: {
            root: {
              '--TextField-brandBorderColor': '#3F4346',
              '--TextField-brandBorderHoverColor': '#70707033',
              '--TextField-brandBorderFocusedColor': '#70707033',
              '& label.Mui-focused': {
                color: 'var(--TextField-brandBorderFocusedColor)',
              },
            },
          },
        },
        MuiOutlinedInput: {
          styleOverrides: {
            notchedOutline: {
              borderColor: 'var(--TextField-brandBorderColor)',
            },
            root: {
              [`&:hover .${outlinedInputClasses.notchedOutline}`]: {
                borderColor: appTheme === appThemes.LIGHT ? 'var(--TextField-brandBorderHoverColor)' : "white",
              },
              [`&.Mui-focused .${outlinedInputClasses.notchedOutline}`]: {
                borderColor: appTheme === appThemes.LIGHT ? 'var(--TextField-brandBorderFocusedColor)' : "white",
                borderWidth: "1px"
              },
              color: appTheme === appThemes.LIGHT ? "black" : "white"
            },
            input: {
              textAlign: "center",

            }
          },
        },


      },
    });
  const { t } = useTranslation();
  const outerTheme = useTheme();
  const theme = useTheme();
  const [descriptionBoxHeight, setDescriptionBoxHeight] = useState(0);
  const [copyBoxHeight, setCopyBoxHeight] = useState(0);

  const [copied, setCopied] = useCopyClipboard(meetingUrl, {
    successDuration: 4000,
  });

  const descriptionBoxRef = useRef();
  const copyBoxRef = useRef();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const showName = queryParams.get("showName");
  const host = queryParams.get("host");
  const guest = queryParams.get("guest");
  const meetingId = queryParams.get("meetingId");
  console.log('guest', guest);
  const [value, setValue] = useState('1');
  const [schedule, setSchedule] = useState();
  // name = localStorage.getItem('fullName') ? localStorage.getItem('fullName') : ''
  const [name, setLocalName] = useState(localStorage.getItem('fullName') ? localStorage.getItem('fullName') : ''); // Local state for name

  // Initialize `name` from localStorage on component mount
  useEffect(() => {
    const savedName = localStorage.getItem('fullName');
    if (savedName) {
      setLocalName(savedName);
      setName(savedName);
    }
  }, []);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  }

  useEffect(() => {
    const fetchSchedule = async () => {
      try {
        // Fetch podcast details
        const response = await getScheduledPodcastsByMeetingId(meetingId); // API call to fetch podcasts
        setSchedule(response?.data?.Data[0]); // Update podcasts data

      } catch (error) {
        console.error("Error fetching podcasts:", error); // Error handling
      } finally {
        // setLoading(false); // End loading after the fetch is complete
      }
    };

    fetchSchedule();
  }, []);

  const handleValidation = () => {
    let isValid = true;
    if (name.length < 3) {
      isValid = false;
      setNameErr(true);
      return false;
    } else {
      setNameErr(false);
    }
    return isValid;
  };

  const { width: windowWidth } = useWindowSize();

  useEffect(() => {
    if (
      descriptionBoxRef.current &&
      descriptionBoxRef.current.offsetHeight !== descriptionBoxHeight
    ) {
      setDescriptionBoxHeight(descriptionBoxRef.current.offsetHeight);
    }
    if (
      copyBoxRef.current &&
      copyBoxRef.current.offsetHeight !== copyBoxHeight
    ) {
      setCopyBoxHeight(copyBoxRef.current.offsetHeight);
    }
  }, [windowWidth]);

  return (
    <>
      <Box
        style={{
          width: "100%",
          display: "flex",
          flex: 1,
          flexDirection: "column",
        }}
      >
        {(meetingUrl && meetingTitle) || meetingUrl ? (
          <Card
            style={{
              borderRadius: 10,
              color: "#95959E",
              backgroundColor:
                appTheme === appThemes.DARK
                  ? theme.palette.darkTheme.slightLighter
                  : appTheme === appThemes.LIGHT
                    ? theme.palette.lightTheme.two
                    : "",
              boxShadow: appTheme === appThemes.LIGHT && "none",
            }}
          >
            <Box p={internalPadding}>
              {meetingTitle ? (
                <div
                  style={{
                    fontFamily: "Roboto, sans-serif",
                    color:
                      appTheme === appThemes.LIGHT
                        ? "#95959E"
                        : "#95959E",
                  }}
                >
                  <LinesEllipsis
                    text={meetingTitle}
                    maxLine="3"
                    ellipsis="..."
                    style={{
                      width: "100%",
                      fontSize: "20px",
                      fontWeight: "bold",

                      fontFamily: "inherit",
                    }}
                  />
                </div>
              ) : null}

              {meetingUrl ? (
                <Box
                  ref={copyBoxRef}
                  mt={meetingTitle ? 2 : 0}
                  p={2.5}
                  pt={1}
                  pb={1}
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    flex: 1,
                    backgroundColor:
                      appTheme === appThemes.DARK
                        ? theme.palette.darkTheme.seven
                        : appTheme === appThemes.LIGHT
                          ? theme.palette.lightTheme.three
                          : "#1C1F2E80",
                    borderRadius: 4,
                    overflow: "hidden",
                    alignItems: "center",
                  }}
                >
                  <Box
                    style={{
                      overflow: "hidden",
                      display: "flex",
                      flex: 1,
                    }}
                  >
                    <Typography
                      style={{
                        width: "100%",
                        textOverflow: "ellipsis",
                        overflow: "hidden",
                        whiteSpace: "nowrap",
                        color:
                          appTheme === appThemes.LIGHT &&
                          "#95959E",
                      }}
                      variant="subtitle1"
                    >
                      {meetingUrl}
                    </Typography>
                  </Box>
                  <Box
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      cursor: "pointer",
                    }}
                  >
                    <Tooltip title={copied ? "Copied" : "Copy"} aria-label="add">
                      <IconButton
                        style={{
                          padding: 0,
                          margin: 0,
                        }}
                        onClick={(e) => {
                          setCopied(e);
                        }}
                      >
                        <CopyIcon
                          fill={
                            appTheme === appThemes.LIGHT
                              ? "#95959E"
                              : "#fff"
                          }
                          style={{
                            cursor: "pointer",
                          }}
                        />
                      </IconButton>
                    </Tooltip>
                  </Box>
                </Box>
              ) : null}
            </Box>
          </Card>
        ) : meetingTitle ? (
          <div
            style={{
              fontFamily: "Roboto, sans-serif",
              color:
                appTheme === appThemes.LIGHT
                  ? "#95959E"
                  : "#95959E",
            }}
          >
            <LinesEllipsis
              text={meetingTitle}
              maxLine="3"
              ellipsis="..."
              style={{
                width: "100%",
                fontSize: "20px",
                fontWeight: "bold",
              }}
            />
          </div>
        ) : null}

        <Box mt={meetingTitle || meetingUrl ? 2 : 0} style={{ width: "100%" }}>
          <TextField
            id={"inputJoin"}
            placeholder={t("Show Name")}
            label="Show Name"
            variant="filled"
            fullWidth
            value={showName}
            disabled={true}
            InputProps={{ style: { color: 'white' } }}
            InputLabelProps={{ style: { color: 'white' } }}
            sx={{ bgcolor: 'rgba(255, 255, 255, 0.1)', borderRadius: 1 }}
          />
          {guest === 'true' &&
            <TextField
              id={"inputJoin"}
              placeholder={t("Host Name")}
              label="Host Name"
              variant="filled"
              fullWidth
              value={host ? host : "Under development"}
              disabled={true}
              InputProps={{ style: { color: 'white' } }}
              InputLabelProps={{ style: { color: 'white' } }}
              sx={{ bgcolor: 'rgba(255, 255, 255, 0.1)', borderRadius: 1, mt: 2 }}
            />
          }
          <ThemeProvider theme={customTheme(outerTheme)}>
            <TextField
              id={"inputJoin"}
              placeholder={t("Enter your name")}
              label="Enter your name"
              variant="filled"
              fullWidth
              value={name || ''}
              InputProps={{ style: { color: 'white' } }}
              InputLabelProps={{ style: { color: 'white' } }}
              sx={{ bgcolor: 'rgba(255, 255, 255, 0.1)', borderRadius: 1, mt: 2 }}
              error={nameErr}
              onChange={(ev) => {
                setLocalName(ev.target.value);
                setName(ev.target.value);
              }}
            />
          </ThemeProvider>
          <p
            style={{
              marginTop: 3,
              marginBottom: 0,
              fontSize: 12,
              marginLeft: 10,
              color:
                appTheme === appThemes.DARK
                  ? theme.palette.darkTheme.four
                  : "#8F927A",
            }}
          >
            {nameErr && `${t("Enter Minimum 3 Characters")}`}
          </p>

          <Button
            color="primary"
            fullWidth
            style={{
              marginTop: 12,
              padding: 12,
              backgroundColor:
                appTheme === appThemes.LIGHT || appTheme === appThemes.DARK
                  ? theme.palette.lightTheme.primaryMain
                  : theme.palette.primary.main,
            }}
            variant="contained"
            onClick={(e) => {
              const isValid = handleValidation();
              if (isValid) {
                startMeeting(e);
              }
            }}
            id={"btnJoin"}
          >
            {t("Join Now")}
          </Button>
        </Box>
      </Box>
      <Tabs
        value={value}
        onChange={handleChange}
        aria-label="wrapped label tabs example"
      >
        <Tab
          value="1"
          label="Agenda"
          style={{ textTransform: 'none' }}
        />
        <Tab value="2" label="Guest Details" style={{ textTransform: 'none' }} />
        <Tab value="3" label="Important links" style={{ textTransform: 'none' }} />
      </Tabs>
      <Box sx={{ padding: 2, color: 'white', alignSelf: 'start' }}>
        {value === "1" && (schedule?.Agenda ? (
          <div style={{ height: '25vh', overflowY: 'auto' }}
            dangerouslySetInnerHTML={{ __html: schedule?.Agenda }} />
        ) : 'The agenda has not been provided.')}
        {value === "2" && (schedule?.GuestInfo ? (
          <div style={{ height: '25vh', overflowY: 'auto' }}
            dangerouslySetInnerHTML={{ __html: schedule?.GuestInfo }} />
        ) : 'The guest info has not been provided.')}
        {value === "3" && (schedule?.ImportantLinks ? (
          <div style={{ height: '25vh', overflowY: 'auto' }}> {schedule?.ImportantLinks} </div>) : 'The important links has not been provided.')}
      </Box>
    </>
  );
}
