import React from 'react'

const HomepageTop = () => {
  return (
    <div id='homepage-top'>
        <span className='homepage-title'>Home Page</span>     
    </div>
  )
}

export default HomepageTop
