import { useTheme } from "@mui/material";
import { useMeeting } from "@videosdk.live/react-sdk";
import React, { useEffect, useMemo, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { appThemes, useMeetingAppContext } from "../../MeetingAppContextDef";
import { meetingLayouts } from "../../MeetingAppContextDef";
import {
  calcQuality,
  getGridForMainParticipants,
  getGridRowsAndColumns,
  localAndPinnedOnTop,
  meetingResolutions,
} from "../../utils/common";
import useIsLGDesktop from "../../utils/useIsLGDesktop";
import useIsMobile from "../../utils/useIsMobile";
import useIsSMDesktop from "../../utils/useIsSMDesktop";
import useIsTab from "../../utils/useIsTab";
import useResponsiveSize from "../../utils/useResponsiveSize";
import { MemoizedMotionParticipant } from "../mainViewContainer/MainViewContainer";
import ParticipantViewer from "../mainViewContainer/ParticipantViewer";
import PresenterView from "../mainViewContainer/PresenterView";
import WhiteboardContainer, {
  convertHWAspectRatio,
} from "../../components/whiteboard/WhiteboardContainer";

import { usePubSub, Constants } from "@videosdk.live/react-sdk";
import { useSelector, useDispatch } from "react-redux";
import { setLogoPosition } from "../../../redux/logoSlice";
import { setBannerPosition } from "../../../redux/bannerSlice";
import { useLocation } from "react-router-dom";

const PinnedLayoutViewContainer = ({
  height,
  width,
  whiteboardToolbarWidth,
  whiteboardSpacing,
}) => {
  const {
    meetingLayout,
    sideBarMode,
    whiteboardStarted,
    animationsEnabled,
    reduceEdgeSpacing,
    isRecorder,
    layoutGridSize,
    appTheme,
    meetingResolution,
  } = useMeetingAppContext();

  const dispatch = useDispatch()
  const location = useLocation();
  const bannerPosition = useSelector((state) => state?.banner);
  const queryParams = new URLSearchParams(location.search);
  const position = queryParams.get('position') || 'bl';
  const color = queryParams.get('color') || 'white';

  const mMeeting = useMeeting();
  const localParticipantId = mMeeting?.localParticipant?.id;
  const presenterId = mMeeting?.presenterId;
  const pinnedParticipants = mMeeting?.pinnedParticipants;

  const isMobile = useIsMobile();
  const isTab = useIsTab();
  const isSMDesktop = useIsSMDesktop();
  const isLGDesktop = useIsLGDesktop();
  const isPortrait = useMediaQuery({ query: "(orientation: portrait)" });
  const mobilePortrait = isMobile && isPortrait;

  const {
    spotlightParticipantId,
    sideBarPinnedParticipantIds,
    spotlightMediaType,
    singleRow,
  } = useMemo(() => {
    let pinnedParticipantIds = [...pinnedParticipants.keys()];

    const pinnedPresenter =
      pinnedParticipantIds.findIndex((id) => id === presenterId) !== -1;

    if (isRecorder && pinnedParticipantIds.length > layoutGridSize) {
      if (pinnedPresenter) {
        const pinnedParticipantIndexToBeRemoved =
          pinnedParticipantIds.findIndex((id) => id === presenterId);

        pinnedParticipantIds.splice(pinnedParticipantIndexToBeRemoved, 1);

        pinnedParticipantIds = [pinnedPresenter, ...pinnedParticipantIds];
      }

      pinnedParticipantIds = pinnedParticipantIds.slice(0, layoutGridSize);
    }

    let obj;

    if (pinnedPresenter) {
      obj = {
        spotlightParticipantId: presenterId,
        sideBarPinnedParticipantIds:
          meetingLayout === meetingLayouts.SPOTLIGHT
            ? []
            : pinnedParticipantIds,
        spotlightMediaType: "SHARE",
      };
    } else if (whiteboardStarted) {
      if (meetingLayout === meetingLayouts.SPOTLIGHT) {
        obj = {
          spotlightParticipantId: "white-board-id",
          sideBarPinnedParticipantIds: [],
          spotlightMediaType: "WHITEBOARD",
        };
      } else {
        obj = {
          spotlightParticipantId: "white-board-id",
          sideBarPinnedParticipantIds: pinnedParticipantIds,
          spotlightMediaType: "WHITEBOARD",
        };
      }
    } else {
      if (meetingLayout === meetingLayouts.SPOTLIGHT) {
        obj = {
          spotlightParticipantId: null,
          sideBarPinnedParticipantIds: pinnedParticipantIds,
          spotlightMediaType: null,
        };
      } else {
        const spotlightParticipantId = pinnedParticipantIds[0];
        pinnedParticipantIds.splice(0, 1);

        obj = {
          spotlightParticipantId: spotlightParticipantId,
          sideBarPinnedParticipantIds: pinnedParticipantIds,
          spotlightMediaType: "WEBCAM",
        };
      }
    }

    if (mobilePortrait && meetingLayout === meetingLayouts.SIDEBAR) {
      const splicesActiveParticipants = obj[
        "sideBarPinnedParticipantIds"
      ].splice(0, 4);

      obj["sideBarPinnedParticipantIds"] = splicesActiveParticipants;
    }

    const participantsCount = obj.sideBarPinnedParticipantIds?.length;

    if (participantsCount) {
      const pinnedParticipantsMap = new Map(pinnedParticipants);

      if (obj["spotlightMediaType"] === "WEBCAM") {
        if (obj["spotlightParticipantId"]) {
          pinnedParticipantsMap.delete(obj["spotlightParticipantId"]);
        }
      }

      const gridInfo = getGridRowsAndColumns({
        participantsCount,
        isMobile:
          mobilePortrait && meetingLayout === meetingLayouts.SIDEBAR
            ? false
            : isMobile,
        isTab:
          mobilePortrait && meetingLayout === meetingLayouts.SIDEBAR
            ? false
            : isTab,
        isSMDesktop:
          mobilePortrait && meetingLayout === meetingLayouts.SIDEBAR
            ? false
            : isSMDesktop,
        isLGDesktop:
          mobilePortrait && meetingLayout === meetingLayouts.SIDEBAR
            ? true
            : isLGDesktop,
        isLandscape:
          mobilePortrait && meetingLayout === meetingLayouts.SIDEBAR
            ? true
            : !isPortrait,
        isPresenting:
          mobilePortrait && meetingLayout === meetingLayouts.SIDEBAR
            ? false
            : !!obj.spotlightParticipantId,
      });

      const { singleRow } = getGridForMainParticipants({
        participants: localAndPinnedOnTop({
          localParticipantId,
          participants: [...obj.sideBarPinnedParticipantIds],
          pinnedParticipantIds: [...pinnedParticipantsMap.keys()],
          moveLocalUnpinnedOnTop: false,
        }),
        gridInfo,
      });

      obj["singleRow"] = singleRow;
    } else {
      obj["singleRow"] = [];
    }

    return obj;
  }, [
    mobilePortrait,
    pinnedParticipants,
    meetingLayout,
    presenterId,
    localParticipantId,
    isMobile,
    isTab,
    isSMDesktop,
    isLGDesktop,
    isPortrait,
    whiteboardStarted,
    isRecorder,
    layoutGridSize,
  ]);

  const theme = useTheme();

  const rowSpacing = useResponsiveSize({
    xl: 24,
    lg: 16,
    md: 14,
    sm: 12,
    xs: 8,
  });

  const gutter = 4;
  const spacing = (reduceEdgeSpacing ? 4 : rowSpacing) - gutter;

  const _presentingSideBarWidth = useResponsiveSize({
    xl: 320,
    lg: 280,
    md: 260,
    sm: 240,
    xs: 200,
  });

  const presentingSideBarWidth = useMemo(() => {
    return (whiteboardStarted || presenterId) &&
      meetingLayout === meetingLayouts.SPOTLIGHT
      ? 0
      : _presentingSideBarWidth;
  }, [_presentingSideBarWidth, whiteboardStarted, presenterId, meetingLayout]);

  const _mainContainerHorizontalPadding =
    spotlightParticipantId && singleRow.length !== 0
      ? 0
      : typeof sideBarMode === "string"
        ? 0
        : sideBarPinnedParticipantIds?.length <= 9
          ? isLGDesktop
            ? singleRow.length === 2
              ? 0
              : 140
            : isSMDesktop
              ? singleRow.length === 2
                ? 20
                : 90
              : isTab && !isPortrait
                ? 60
                : 0
          : 0;

  const mainContainerHorizontalPadding = useMemo(() => {
    return reduceEdgeSpacing
      ? 0
      : (whiteboardStarted || presenterId) &&
        meetingLayout === meetingLayouts.SPOTLIGHT
        ? 0
        : _mainContainerHorizontalPadding;
  }, [
    _mainContainerHorizontalPadding,
    whiteboardStarted,
    presenterId,
    meetingLayout,
    reduceEdgeSpacing,
  ]);

  const gridVerticalSpacing = useResponsiveSize({
    xl: 160,
    lg: 90,
    md: 90,
    sm: 60,
    xs: 60,
  });


  const [bannerName, setBannerName] = useState('Default Banner');
  const [backgroundColor, setBackgroundColor] = useState('#00002910');
  const [fontSize, setFontSize] = useState('20px');

  const [logoDragging, setLogoDragging] = useState(false);
  const [dragging, setDragging] = useState(false);
  const logoData = useSelector((state) => state?.logo);
  const [logo, setLogo] = useState("");
  const [logoPosition, setPosition] = useState({ x: 0, y: 0 })

  useEffect(() => {
    console.log("check",logoData?.logoFile)
    if ((logoData?.logoFile)!== null) {
      console.log("conditionTrue")
      setLogo(URL.createObjectURL(logoData?.logoFile))
      setPosition({ x: logoData?.x, y: logoData?.y })
    }
  }, [logoData]);

  usePubSub("UPDATE_BANNER", {
    onMessageReceived: (data) => {
      const { message } = data;
      console.log("Message", message)
      setBannerName(message?.message);  // Update bannerName state here
      setBackgroundColor(message?.message ? message?.backgroundColor : 'transparent');
      setFontSize(message?.fontSize);
      // enqueueSnackbar(`New Banner - ${message}`);
    },
    onOldMessagesReceived: (messages) => {
      console.log("Old Message published with persist:true Received", messages);
      // Check if any old message exists, and if so, set it to the state
      if (messages && messages.length > 0) {
        const latestMessage = messages[messages.length - 1].message; // Get the most recent banner message
        setBannerName(latestMessage?.message);  // Update bannerName state
        setBackgroundColor(latestMessage?.message ? latestMessage?.backgroundColor : 'transparent'); // Update background color
        setFontSize(latestMessage?.fontSize); // Update font size
      }
    },
  });

  usePubSub("UPDATE_LOGO", {
    onMessageReceived: (data) => {
      console.log("test here")
      const { message } = data;
      setLogo(message?.LogoUrl);
      setPosition({ x: message?.LogoPositionX, y: message?.LogoPositionY })
    },
    onOldMessagesReceived: (messages) => {
      console.log("Old Message published with persist:true Received", messages);
      // Check if any old message exists, and if so, set it to the state
      if (messages && messages.length > 0) {
        const latestMessage = messages[messages.length - 1].LogoUrl; // Get the most recent banner message
        setLogo(latestMessage?.LogoUrl);
      }
    },
  });

  const bannerStyles = {
    position: 'absolute',
    left: `${bannerPosition.x}%`,
    top: `${bannerPosition.y}%`,
    transform: position === 'cc' ? 'translate(-50%, -50%)' : 'none',
    backgroundColor: backgroundColor,
    color: color,
    padding: '10px 20px',
    fontSize: fontSize,
    textAlign: 'center',
    borderRadius: '5px',
    zIndex: 1000,
  };

  const logoStyles = {
    position: 'absolute',
    top: `${logoPosition.y}%`,  // Aligns it at the top
    left: `${logoPosition.x}%`,
    transform: 'none',  // No transform needed for top-left
    backgroundColor: 'transparent',  // Background color for logo can be transparent
    zIndex: 1000,  // Ensures the logo stays on top
  };

  const handlelogoMouseDown = (e) => {
    setLogoDragging(true);
  };

  const handleMouseDown = (e) => {
    setDragging(true);
  };
  const handleMouseUp = () => {
    setDragging(false);
    setLogoDragging(false);
  };

  const handleMouseMove = (e) => {
    const container = e.target.parentElement;
    const { left, top, width, height } = container.getBoundingClientRect();

    let newX = ((e.clientX - left) / width) * 100;
    let newY = ((e.clientY - top) / height) * 100;

    newX = Math.max(0, Math.min(100, newX));
    newY = Math.max(0, Math.min(100, newY));
    if (dragging && newX <= 82 && newY <= 88) {
      dispatch(setBannerPosition({ x: newX, y: newY }));
    }

    if (logoDragging && newX <= 89 && newY <= 82) {
      dispatch(setLogoPosition({ x: newX, y: newY }));
    }
  };

  return (
    <div
      style={{
        height,
        width,
        backgroundColor:
          appTheme === appThemes.DARK
            ? theme.palette.darkTheme.main
            : appTheme === appThemes.LIGHT
              ? theme.palette.lightTheme.main
              : theme.palette.background.default,
        overflow: "hidden",
        transition: `width ${400 * (animationsEnabled ? 1 : 0.5)}ms`,
        transitionTimingFunction: "ease-in-out",
        display: "flex",
        flexDirection:
          mobilePortrait && meetingLayout === meetingLayouts.SIDEBAR
            ? "column"
            : "row",
        position: "relative"
      }}
      onMouseMove={handleMouseMove}
      onMouseUp={handleMouseUp}
    >
      <div
        style={{
          width: spotlightParticipantId
            ? sideBarPinnedParticipantIds?.length > 0
              ? width - presentingSideBarWidth
              : width
            : 0,
          height: mobilePortrait && !presenterId ? height / 2 : height,
          transition: `width ${800 * (animationsEnabled ? 1 : 0.5)}ms`,
          transitionTimingFunction: "ease-in-out",
          paddingLeft: spacing,
          paddingTop: spacing,
        }}
      >
        <div
          style={{
            height:
              mobilePortrait && !presenterId
                ? height / 2 - 2 * spacing
                : height - 2 * spacing,
            width: mobilePortrait
              ? width - 2 * spacing
              : (spotlightParticipantId
                ? (sideBarPinnedParticipantIds?.length > 0
                  ? width - presentingSideBarWidth
                  : width) -
                2 * spacing
                : 0) -
              (singleRow.length === 0
                ? 2 * mainContainerHorizontalPadding
                : 0),
            transition: `width ${800 * (animationsEnabled ? 1 : 0.5)}ms`,
            transitionTimingFunction: "ease-in-out",
            borderRadius: theme.spacing(1),
            overflow: "hidden",
            position: "relative",
            marginLeft:
              singleRow.length === 0 ? mainContainerHorizontalPadding : 0,
            marginRight:
              singleRow.length === 0 ? mainContainerHorizontalPadding : 0,
          }}
        >
          {spotlightParticipantId ? (
            spotlightMediaType === "SHARE" ? (
              <PresenterView
                key={`spotlightParticipantId_${spotlightParticipantId}`}
                presenterId={spotlightParticipantId}
              />
            ) : spotlightMediaType === "WHITEBOARD" ? (
              <WhiteboardContainer
                {...{
                  ...convertHWAspectRatio({
                    height:
                      height -
                      2 * spacing -
                      (whiteboardToolbarWidth === 0 ? 2 * 16 : 0),
                    width: whiteboardStarted
                      ? width -
                      (isMobile ? 0 : presentingSideBarWidth) -
                      2 * spacing -
                      (whiteboardToolbarWidth + 2 * whiteboardSpacing) -
                      (whiteboardToolbarWidth === 0 ? 2 * 16 : 0)
                      : 0,
                  }),
                  whiteboardToolbarWidth,
                  whiteboardSpacing,
                  originalHeight: height - 2 * spacing,
                  originalWidth:
                    width -
                    (isMobile ? 0 : presentingSideBarWidth) -
                    2 * spacing,
                }}
              />
            ) : (
              <MemoizedMotionParticipant
                {...{
                  participantId: spotlightParticipantId,
                  gutter,
                  quality: meetingResolution
                    ? meetingResolution === meetingResolutions.SD
                      ? "s1t2"
                      : meetingResolution === meetingResolutions.HD && "s2t2"
                    : "s2t2",
                  relativeHeight: 100,
                  relativeWidth: 100,
                  relativeTop: 0,
                  relativeLeft: 0,
                }}
                key={`spotlightParticipantId_${spotlightParticipantId}`}
              />
            )
          ) : null}
        </div>
      </div>

      {singleRow.length === 0 ||
        (mobilePortrait && presenterId) ? null : mobilePortrait &&
          meetingLayout === meetingLayouts.SIDEBAR ? (
        <div
          style={{
            height: "50%",
            width: "100%",
            display: "flex",
            position: "relative",
          }}
        >
          {singleRow.map(
            ({
              participantId,
              relativeHeight,
              relativeWidth,
              relativeTop,
              relativeLeft,
            }) => {
              return (
                <div
                  style={{
                    padding: 8,
                    position: "absolute",
                    top: `${relativeTop}%`,
                    left: `${relativeLeft}%`,
                    width: `${relativeWidth}%`,
                    height: `${relativeHeight}%`,
                  }}
                  id={`pinned_sidebar_${participantId}`}
                  key={`pinned_sidebar_${participantId}`}
                >
                  <div
                    style={{
                      height: `calc(100% - ${2 * 8}px)`,
                      width: `calc(100% - ${2 * 8}px)`,
                    }}
                  >
                    <ParticipantViewer
                      participantId={participantId}
                      quality={"low"}
                    />
                  </div>
                </div>
              );
            }
          )}
        </div>
      ) : (
        <div
          style={{
            backgroundColor:
              appTheme === appThemes.DARK
                ? theme.palette.darkTheme.main
                : appTheme === appThemes.LIGHT
                  ? theme.palette.lightTheme.main
                  : theme.palette.background.default,
            overflowX: "hidden",
            overflowY: spotlightParticipantId ? "scroll" : "hidden",
            width: spotlightParticipantId ? presentingSideBarWidth : width,
            height: '90vh',
            margin: spacing,
            transition: `all ${800 * (animationsEnabled ? 1 : 0.5)}ms`,
            transitionTimingFunction: "ease-in-out",
            paddingLeft: mainContainerHorizontalPadding,
            paddingRight: mainContainerHorizontalPadding,
            paddingTop:
              singleRow.length === 2 && !spotlightParticipantId && !isMobile
                ? gridVerticalSpacing
                : 0,
          }}
        >
          <div
            style={{
              height:
                (spotlightParticipantId
                  ? (singleRow.length * presentingSideBarWidth * 2) / 3
                  : height - 2 * spacing) -
                (singleRow.length === 2 && !spotlightParticipantId && !isMobile
                  ? 2 * gridVerticalSpacing
                  : 0),
              position: "relative",
              transition: `height ${800 * (animationsEnabled ? 1 : 0.5)}ms`,
              transitionTimingFunction: "ease-in-out",
            }}
          >
            {singleRow.map((c) => (
              <MemoizedMotionParticipant
                {...c}
                quality={
                  meetingResolution
                    ? meetingResolution === meetingResolutions.SD
                      ? "s1t2"
                      : meetingResolution === meetingResolutions.HD && "s2t2"
                    : calcQuality(singleRow?.length)
                }
                key={`pinned_${c.participantId}`}
                gutter={gutter}
                useVisibilitySensor={presenterId ? true : false}
              />
            ))}
          </div>
        </div>
      )}
      {(bannerName !== 'Default Banner' || !bannerName) && (
        <div style={bannerStyles}
          onMouseDown={handleMouseDown}
        >
          {bannerName}
        </div>
      )}
      {logo !== null && (
        <div style={logoStyles}
          onMouseDown={handlelogoMouseDown}
        >
          <img
            src={logo}
            alt="Logo"
            style={{ width: '150px', height: '150px', objectFit: 'contain' }}  // Adjust size as needed
          />

        </div>
      )}
    </div>
  );
};

export default React.memo(
  PinnedLayoutViewContainer,
  (prevProps, nextProps) =>
    prevProps.width === nextProps.width && prevProps.height === nextProps.height
);
