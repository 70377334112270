import * as React from 'react';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

export default function Dropdowncomponent() {
  const [age, setAge] = React.useState('Select');

  const handleChange = (event) => {
    setAge(event.target.value);
  };

  return (
    <Box sx={{ minWidth: 180, border: '1px solid rgb(42, 42, 86)', borderRadius: '4px' }}>
      <FormControl fullWidth>
        <Select
          value={age}
          onChange={handleChange}
          displayEmpty
          inputProps={{ 'aria-label': 'Without label' }}
          sx={{
            color: 'white', // Change text color to white
            '.MuiSelect-icon': { color: 'white' }, // Change dropdown icon color
          }}
        >
          <MenuItem value={10}>Engagement</MenuItem>
          <MenuItem value={10}>Breakdown</MenuItem>
        </Select>
      </FormControl>
    </Box>
  );
}
