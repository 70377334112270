import React, { useState } from "react";
import {
    ProSidebar,
    Menu,
    MenuItem,
    SidebarHeader,
    SidebarFooter,
    SidebarContent,
} from "react-pro-sidebar";
import { HiCog } from "react-icons/hi";
import { FiLogOut } from "react-icons/fi";
import GroupsIcon from '@mui/icons-material/Groups';
import CreateIcon from '@mui/icons-material/Create';
import "react-pro-sidebar/dist/css/styles.css";
import "./Components.css";
import { useLocation, useNavigate } from "react-router-dom";
import { faBagShopping, faGripHorizontal } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import logo from "./../Assests/dyrect-logo.png";
import LogoutIcon from '@mui/icons-material/Logout';
import SettingsRoundedIcon from '@mui/icons-material/SettingsRounded';
import HomeRoundedIcon from '@mui/icons-material/HomeRounded';


const SideBar = () => {
    const [menuCollapse, setMenuCollapse] = useState(true);  // Sidebar starts collapsed

    const location = useLocation();
    const { pathname } = location;
    const navigate = useNavigate();

    const DEFAULT_APPLICATION = [
        {
            label: "Home",
            path: "/home",
            icon: <HomeRoundedIcon className="sidebar-icon" />
        },
        {
            label: "Shows",
            path: "/shows",
            icon: <FontAwesomeIcon icon={faGripHorizontal} className="sidebar-icon" />
        },
        { label: "Create", path: "/schedules", icon: <CreateIcon className="sidebar-icon" /> },
        { label: "Brand Central", path: "/brand-central", icon: <FontAwesomeIcon icon={faBagShopping} className="sidebar-icon" /> },
        { label: "Community", path: "/community", icon: <GroupsIcon className="sidebar-icon" /> },
        { label: "Settings", path: "/settings", icon: <SettingsRoundedIcon className="sidebar-icon" /> }
    ];

    const logout = () => {
        localStorage.clear();
        navigate('/login');
        window.location.reload();
    };

    const handleNavigate = (path) => {
        navigate(path);
    };

    // Use hover events to trigger collapse and expand
    const handleMouseEnter = () => {
        setMenuCollapse(false);  // Expand on hover
    };

    const handleMouseLeave = () => {
        setMenuCollapse(true);  // Collapse when hover ends
    };

    return (
        <div className="sidebar" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
            <ProSidebar collapsed={menuCollapse} className="height-100">
                <SidebarHeader className="theme-bg-light sidebar-header">
                    <div className="p-3 main-logo-container" style={{ minHeight: '12vh' }}>
                        <img src={logo} alt="Dyrect Logo" className="logo-image" />
                        {!menuCollapse && (
                            <span className="main-logo-text">yrect</span>
                        )}
                    </div>
                </SidebarHeader>
                <SidebarContent className="theme-bg-light">
                    <Menu iconShape="square">
                        {DEFAULT_APPLICATION.map((item, id) => (
                            <MenuItem
                                key={id}
                                active={pathname.includes(item.path)}
                                className={"mt-3"}
                                onClick={() => handleNavigate(item.path)}
                            >
                                {item.icon} {item.label}
                            </MenuItem>
                        ))}
                    </Menu>
                </SidebarContent>
                <SidebarFooter className="theme-bg-light">
                    <Menu iconShape="square">
                        <MenuItem onClick={logout}>
                            <LogoutIcon className="sidebar-icon" />
                            <span className="ml-3"> Logout</span>
                        </MenuItem>
                    </Menu>
                </SidebarFooter>
            </ProSidebar>
        </div>
    );
};

export default SideBar;
