import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { 
  userPool, 
  setupTOTP, 
  verifyTOTP, 
  restoreSession, 
  refreshSession, 
  requestEmailMFACode, 
  setupEmailMFA, 
  verifyEmailMFA 
} from '../Services/AuthService';
import { QRCodeCanvas } from 'qrcode.react'; 
import './Pages.css';



const SetupAuthenticator = () => {
  const [secretCode, setSecretCode] = useState('');
  const [verificationCode, setVerificationCode] = useState('');
  const [error, setError] = useState('');
  const [isEmailMFA, setIsEmailMFA] = useState(false);  // Track if email MFA is selected
  const [isLoading, setIsLoading] = useState(true);
  const [isCodeSent, setIsCodeSent] = useState(false); // Track email code request
  const navigate = useNavigate();

  useEffect(() => {
    const setup = async () => {
      setIsLoading(true);
      setError('');
      
      try {
        const cognitoUser = userPool.getCurrentUser();
        if (!cognitoUser) {
          setError('No authenticated user found. Please sign in again.');
          navigate('/login');
          return;
        }

        // Restore and validate session
        try {
          await restoreSession(cognitoUser);
          const session = cognitoUser.getSignInUserSession();
          
          if (!session || !session.isValid()) {
            await refreshSession(cognitoUser);
          }
        } catch (sessionError) {
          console.error('Session error:', sessionError);
          setError('Session expired. Please sign in again.');
          navigate('/login');
          return;
        }

        // Setup MFA based on type
        if (!isEmailMFA) {
          try {
            const code = await setupTOTP(cognitoUser);
            setSecretCode(code);
          } catch (totpError) {
            console.error('TOTP setup error:', totpError);
            setError('Failed to setup TOTP. Please try again.');
            return;
          }
        } else {
          try {
            await requestEmailMFACode(cognitoUser);
            setIsCodeSent(true);
          } catch (emailError) {
            console.error('Email MFA setup error:', emailError);
            setError('Failed to send verification code. Please try again.');
            return;
          }
        }
      } catch (err) {
        console.error("Setup error:", err);
        setError('Failed to setup authenticator. Please try again.');
      } finally {
        setIsLoading(false);
      }
    };

    setup();
  }, [isEmailMFA, navigate]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');

    if (!verificationCode) {
      setError('Please enter a verification code');
      return;
    }

    try {
      const cognitoUser = userPool.getCurrentUser();
      if (!cognitoUser) {
        setError('No authenticated user found. Please sign in again.');
        navigate('/login');
        return;
      }

      // Verify session is valid
      await restoreSession(cognitoUser);
      const session = cognitoUser.getSignInUserSession();
      
      if (!session || !session.isValid()) {
        try {
          await refreshSession(cognitoUser);
        } catch (sessionError) {
          console.error('Session refresh error:', sessionError);
          setError('Session expired. Please sign in again.');
          navigate('/login');
          return;
        }
      }

      // Handle MFA verification
      if (isEmailMFA) {
        try {
          await verifyEmailMFA(cognitoUser, verificationCode); // Changed to pass cognitoUser instead of session
          await setupEmailMFA(cognitoUser);
          console.log('Email MFA setup completed successfully');
        } catch (mfaError) {
          console.error('MFA verification error:', mfaError);
          throw new Error('Failed to verify email code. Please try again.');
        }
      } else {
        try {
          await verifyTOTP(cognitoUser, verificationCode);
          console.log('TOTP verification completed successfully');
        } catch (totpError) {
          console.error('TOTP verification error:', totpError);
          throw new Error('Failed to verify TOTP code. Please try again.');
        }
      }

      navigate('/login');
    } catch (err) {
      console.error("Verification error:", err);
      setError(err.message || 'Failed to verify code. Please try again.');
    }
  };

  return (
    <div className="auth-container">
      <div className="auth-form">
        <h2 className="clr-white">Setup Multi-Factor Authentication</h2>
        {error && <p style={{ color: 'red' }}>{error}</p>}

        {isLoading ? (
          <p>Loading MFA setup...</p>
        ) : (
          <>
            <div>
              <label>
                <input
                  type="radio"
                  name="mfaOption"
                  checked={!isEmailMFA}
                  onChange={() => setIsEmailMFA(false)}
                />
                Use Authenticator App
              </label>
              <label>
                <input
                  type="radio"
                  name="mfaOption"
                  checked={isEmailMFA}
                  onChange={() => setIsEmailMFA(true)}
                />
                Use Email MFA
              </label>
            </div>

            {/* Show authenticator setup if selected */}
            {!isEmailMFA && secretCode ? (
              <>
                <p>Please follow these steps to set up your authenticator app:</p>
                <ol>
                  <li>Open your authenticator app (e.g., Google Authenticator, Authy, Microsoft Authenticator).</li>
                  <li>Scan this QR code with your app:</li>
                </ol>
                <div className="qr-code-container">
                  <QRCodeCanvas value={`otpauth://totp/DyrectAI:${userPool.getCurrentUser().getUsername()}?secret=${secretCode}&issuer=DyrectAI`} />
                </div>
                <p>Or manually enter this secret code in your app: {secretCode}</p>
              </>
            ) : isEmailMFA && isCodeSent ? (
              <p>An email with a verification code has been sent. Please check your inbox.</p>
            ) : (
              <p>Preparing Email MFA setup...</p>
            )}

            <form onSubmit={handleSubmit}>
              <input
                type="text"
                placeholder={isEmailMFA ? "Enter code from email" : "Enter code from authenticator app"}
                value={verificationCode}
                onChange={(e) => setVerificationCode(e.target.value)}
                required
              />
              <button type="submit">Verify and Enable MFA</button>
            </form>
          </>
        )}
      </div>
    </div>
  );
};

export default SetupAuthenticator;
