import React, { useState } from "react";
import { Box, Tabs, Tab, Divider } from "@mui/material";
import { a11yProps, CustomTabPanel } from "./CustomTabPanel"; // You can use your existing CustomTabPanel
import BannerPanel from "./BannerPanel"; // The Banner Panel you already have
import LogosPanel from "./LogosPanel"; // The new Logos Panel component you'll create

const BannersLogosTabs = () => {
    const [value, setValue] = useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <Box sx={{ width: '100%' }}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={value} onChange={handleChange} aria-label="Tabs for banners and logos">
                    <Tab label="Banners" {...a11yProps(0)} sx={{ textTransform: 'none' }} />
                    <Tab label="Logos" {...a11yProps(1)} sx={{ textTransform: 'none' }} />
                </Tabs>
            </Box>

            <CustomTabPanel value={value} index={0}>
                <BannerPanel />
            </CustomTabPanel>

            <CustomTabPanel value={value} index={1}>
                <LogosPanel />
            </CustomTabPanel>
        </Box>
    );
};

export default BannersLogosTabs;
