import axios from "axios";
const dyrectApi = process.env.REACT_APP_DYRECT_API_URL;

export const addNoParticipantStartTime = async (data) => {
    try {
        const path = "meeting/participants";
        const response = await axios.post(dyrectApi + path, data, {
            headers: {
                'Content-Type': 'application/json',
            }
        });
        return response;
    } catch (error) {
        console.error("Error adding participants:", error);
        return 'Unable to add participants';
    }
};

export const updateParticipantsEndTime = async (data) => {
    try {
        const path = "meeting/participants";
        const response = await axios.put(dyrectApi + path, data, {
            headers: {
                'Content-Type': 'application/json',
            }
        });
        return response;
    } catch (error) {
        console.error("Error updating participants' end time:", error);
        return 'Unable to update end time';
    }
};
