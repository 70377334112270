import { createSlice } from '@reduxjs/toolkit'

export const dataSlice = createSlice({
  name: 'data',
  initialState: { item_id: [] },
  reducers: {
    setDataValue: (state, action) => {
      return { ...state, ...action.payload }
    },
    addData: (state, action) => {
      state.item_id = [...state.item_id, action.payload]
    },
    removeData: (state, action) => {
      state.item_id = state.item_id.filter(
        (item) => item.participantId !== action.payload.participantId
      );
    },
  },
})

export const { setDataValue,addData,removeData } = dataSlice.actions

export default dataSlice.reducer