import { CognitoIdentityProviderClient, SetUserMFAPreferenceCommand, VerifyUserAttributeCommand,GetUserAttributeVerificationCodeCommand } from "@aws-sdk/client-cognito-identity-provider";
 

import { ConsoleLogger } from "amazon-chime-sdk-js";
import  {Amplify} from 'aws-amplify';
import awsConfig from './amplifyConfig'; 



import {
    CognitoUserPool,
    CognitoUser,
    AuthenticationDetails,
    CognitoUserSession,
    CognitoAccessToken,
    CognitoIdToken,
    CognitoRefreshToken,
    CognitoUserAttribute,
   
} from 'amazon-cognito-identity-js'; 
import { ConnectContactLens } from "aws-sdk";
import { useCallback } from "react";


import { signIn, confirmSignIn,signOut,currentAuthenticatedUser,getPreferredMFA} from 'aws-amplify/auth';

const AWS=require('aws-sdk');

const cognito = new AWS.CognitoIdentityServiceProvider({
    region: 'us-east-1'
  }); 



const poolData = {
    UserPoolId: process.env.REACT_APP_USER_POOL_ID,
    ClientId: process.env.REACT_APP_CLIENT_ID,
};


export const userPool = new CognitoUserPool(poolData);
console.log("userPool", userPool);


export const signInTOTP = (username, password) => {
    return new Promise((resolve, reject) => {
        const authenticationDetails = new AuthenticationDetails({
          Username: username,
          Password: password,
        });
    
        console.log("authenticationDetails", authenticationDetails);
    
        const cognitoUser = new CognitoUser({
          Username: username,
          Pool: userPool
        });
        console.log("cognitoUser", cognitoUser);
        console.log("cognitoUser authenticateUser", cognitoUser.authenticateUser);
    
        cognitoUser.authenticateUser(authenticationDetails, {
          onSuccess: (result) => {
            resolve(result);
          },
          onFailure: (err) => {
            reject(err);
          },
        
        });
      });
    };
    
  
    export const signInWithEmail = async (username, password) => {
      try {
          console.log("Initiating sign-in with email...");
          
          const user = await signIn({ 
              username, 
              password,
              options: {
                  authFlowType: "USER_PASSWORD_AUTH"
              }
          });
          
          console.log("Sign-in full response:", user);
  
          return { 
              userConfirmationNecessary: true,
              challengeName: 'EMAIL_OTP',
              signInResponse: user  // Store the complete user object
          };
      } catch (error) {
          console.error("Sign-in failed:", error);
          throw error;
      }
  };

export const signOutCurrentUser = async () => {
    try {
      // If the current user is authenticated, sign them out
      await signOut();
      console.log("User signed out successfully.");
    } catch (error) {
      console.warn("No user session found to sign out.");
    }
  };
  export const requestEmailMFACode = async (cognitoUser) => {
    try {
      const session = cognitoUser.getSignInUserSession();
      if (!session) {
        throw new Error("User session not found");
      }
  
      const client = new CognitoIdentityProviderClient({ 
        region: 'us-east-1'
      });
  
      const params = {
        AccessToken: session.getAccessToken().getJwtToken(),
        AttributeName: 'email'
      };
  
      const command = new GetUserAttributeVerificationCodeCommand(params);
      const response = await client.send(command);
      
      console.log("Verification code sent to email");
      return response;
    } catch (error) {
      console.error('Error requesting email verification code:', error);
      throw error;
    }
  };
  
  export const setupEmailMFA = async (cognitoUser) => {
    try {
      const session = cognitoUser.getSignInUserSession();
      if (!session) {
        throw new Error("User session not found");
      }
  
      const accessToken = session.getAccessToken().getJwtToken();
  
      // Initialize the client
      const client = new CognitoIdentityProviderClient({ 
        region: 'us-east-1'
      });
  
      const input = {
        AccessToken: accessToken,
        EmailMfaSettings: {
          Enabled: true,
          PreferredMfa: true
        },
        SMSMfaSettings: {
          Enabled: false,
          PreferredMfa: false
        },
        SoftwareTokenMfaSettings: {
          Enabled: false,
          PreferredMfa: false
        }
      };
  
      const command = new SetUserMFAPreferenceCommand(input);
      const response = await client.send(command);
      
      return response;
    } catch (error) {
      console.error('Error setting up Email MFA:', error);
      throw error;
    }
  };
  // In AuthService.js
  export const verifyEmailMFA = async (cognitoUser, code) => {
      if (!cognitoUser) {
        throw new Error("No user provided to verifyEmailMFA");
      }
  
      if (!code) {
        throw new Error("Verification code is required");
      }
  
      try {
        const session = cognitoUser.getSignInUserSession();
        if (!session) {
          throw new Error("User session not found - please sign in again");
        }
  
        const client = new CognitoIdentityProviderClient({
          region: 'us-east-1'
        });
    
        const params = {
          AccessToken: session.getAccessToken().getJwtToken(),
          AttributeName: 'email',
          Code: code
        };
    
        const command = new VerifyUserAttributeCommand(params);
        const response = await client.send(command);
        
        console.log("Email MFA verification successful");
        return response;
      } catch (error) {
        console.error("Error verifying email MFA:", error);
        throw error;
      }
  };

   





export const verifyEmailOTP = async (code, signInResponse) => {
    try {
        console.log("Inside verifyEmailOTP function", { code });
        
        const result = await confirmSignIn({
            challengeName: "CONFIRM_SIGN_IN_WITH_EMAIL_CODE",
            challengeResponse: code,  // Add this line
            options: {
                serviceOptions: {
                    ...signInResponse.nextStep.codeDeliveryDetails
                }
            }
        });

        console.log("Verification response:", result);

        if (result.isSignedIn) {
            return { success: true };
        }

        return {
            success: false
        };
    } catch (error) {
        console.error("Email OTP verification failed:", error);
        throw error;
    }
};

//   export const signInWithEmail = async (username, password) => {
//     try {
//         console.log("Initiating sign-in with email...");
        
//         const { nextStep } = await signIn({ 
//             username, 
//             password
//         });
        
//         console.log("Sign-in nextStep:", nextStep);

//         switch (nextStep.signInStep) {
//             case 'CONFIRM_SIGN_IN_WITH_EMAIL_CODE':
//                 return {
//                     userConfirmationNecessary: true,
//                     challengeName: 'EMAIL_OTP',
//                     cognitoUser: nextStep
//                 };

//             case 'CONFIRM_SIGN_IN_WITH_SOFTWARE_TOKEN_MFA':
//                 return {
//                     userConfirmationNecessary: true,
//                     challengeName: 'SOFTWARE_TOKEN_MFA',
//                     cognitoUser: nextStep
//                 };

//             case 'CONFIRM_SIGN_IN_WITH_SMS_CODE':
//                 return {
//                     userConfirmationNecessary: true,
//                     challengeName: 'SMS_MFA',
//                     cognitoUser: nextStep
//                 };

//             case 'DONE':
//                 return {
//                     success: true,
//                     user: nextStep
//                 };

//             default:
//                 return {
//                     success: false,
//                     challengeName: nextStep.signInStep,
//                     cognitoUser: nextStep
//                 };
//         }
//     } catch (error) {
//         console.error("Sign-in failed:", error);
//         throw error;
//     }
// };

// export const verifyEmailOTP = async (code) => {
//     try {
//         const { isSignedIn, nextStep } = await confirmSignIn({
//             confirmationCode: code
//         });

//         console.log("MFA verification response:", { isSignedIn, nextStep });

//         if (isSignedIn && nextStep.signInStep === 'DONE') {
//             return { success: true };
//         }

//         return {
//             success: false,
//             challengeName: nextStep.signInStep
//         };
//     } catch (error) {
//         console.error("MFA verification failed:", error);
//         throw error;
//     }
// };
  
  // Modified handleLogin function
 
  

// Check if the user is authenticated
export const isAuthenticated = () => {
    return new Promise((resolve, reject) => {
        const cognitoUser = userPool.getCurrentUser();
        if (cognitoUser) {
            cognitoUser.getSession((err, session) => {
                if (err) {
                    reject(err);
                } else {
                    const accessToken = session.getAccessToken().getJwtToken();
                    const idToken = session.getIdToken().getJwtToken();
                    const refreshToken = session.getRefreshToken().getToken();

                    localStorage.setItem('accessToken', accessToken);
                    localStorage.setItem('idToken', idToken);
                    localStorage.setItem('refreshToken', refreshToken);

                    resolve(session.isValid());
                }
            });
        } else {
            resolve(false);
        }
    });
};

// Sign-up function
export const signUp = (username, email, password) => {
    return new Promise((resolve, reject) => {
        const attributes = [
            { Name: 'email', Value: email }
        ];

        userPool.signUp(username, password, attributes, null, (err, result) => {
            if (err) {
                reject(err);
            } else {
                resolve(result);
            }
        });
    });
};

// Confirm Sign-up function
export const confirmSignUp = (username, confirmationCode) => {
    return new Promise((resolve, reject) => {
        const cognitoUser = new CognitoUser({
            Username: username,
            Pool: userPool,
        });

        cognitoUser.confirmRegistration(confirmationCode, true, (err, result) => {
            if (err) {
                reject(err);
            } else {
                resolve(result);
            }
        });
    });
};

// Forgot password function (to send a verification code)
export const forgotPassword = (username) => {
    return new Promise((resolve, reject) => {
        const cognitoUser = new CognitoUser({
            Username: username,
            Pool: userPool,
        });

        cognitoUser.forgotPassword({
            onSuccess: (result) => {
                resolve(result);
            },
            onFailure: (err) => {
                reject(err);
            },
        });
    });
};

// Confirm password function (to reset the password using the verification code)
export const confirmPassword = (username, verificationCode, newPassword) => {
    return new Promise((resolve, reject) => {
        const cognitoUser = new CognitoUser({
            Username: username,
            Pool: userPool,
        });

        cognitoUser.confirmPassword(verificationCode, newPassword, {
            onSuccess: (result) => {
                resolve(result);
            },
            onFailure: (err) => {
                reject(err);
            },
        });
    });
};

export const setupTOTP = (cognitoUser) => {
    return new Promise((resolve, reject) => {
        cognitoUser.associateSoftwareToken({
            associateSecretCode: (secretCode) => {
                console.log("secretCode ", secretCode)
                resolve(secretCode);
            },
            onFailure: (err) => {
                console.log("err ", err)
                reject(err);
            }
        });
    });
};

export const verifyTOTP = (cognitoUser, totpCode) => {
    return new Promise((resolve, reject) => {
        // Retrieve session from localStorage
        const sessionData = {
            IdToken: new CognitoIdToken({ IdToken: localStorage.getItem('idToken') }),
            AccessToken: new CognitoAccessToken({ AccessToken: localStorage.getItem('accessToken') }),
            RefreshToken: new CognitoRefreshToken({ RefreshToken: localStorage.getItem('refreshToken') })
        };

        const cachedSession = new CognitoUserSession(sessionData);
        cognitoUser.setSignInUserSession(cachedSession);

        // Verify the TOTP code
        cognitoUser.verifySoftwareToken(totpCode, 'My TOTP device', {
            onSuccess: (result) => {
                console.log("TOTP verified successfully:", result);

                // After successful verification, set TOTP as the preferred MFA method
                cognitoUser.setUserMfaPreference(null, { PreferredMfa: true, Enabled: true }, (err, result) => {
                    if (err) {
                        console.error("Error setting TOTP as preferred MFA:", err);
                        reject(err);
                    } else {
                        console.log("TOTP set as preferred MFA:", result);

                        // Get the current session to update the tokens
                        cognitoUser.getSession((err, session) => {
                            if (err) {
                                console.error("Error fetching session after TOTP verification:", err);
                                reject(err);
                            } else {
                                resolve(result);
                            }
                        });
                    }
                });
            },
            onFailure: (err) => {
                console.error("Error verifying TOTP:", err);
                reject(err);
            }
        });
    });
};

export const signInWithTOTP = (cognitoUser, totpCode) => {
    // const navigate = useNavigate();
    return new Promise((resolve, reject) => {
        cognitoUser.sendMFACode(
            totpCode,
            {
                onSuccess: async function (session) {
                    console.log('MFA successful', session);
                    localStorage.setItem('authenticated', true);
                    console.log('Local storage authenticated:', localStorage.getItem('authenticated'));
                    console.log(session.getIdToken());
                    localStorage.setItem('user_id', session.getIdToken().payload['sub']);
                    // SessionService.setAuthenticated(true);
                    resolve(session);
                },
                onFailure: function (err) {
                    console.error('MFA failed', err);
                    reject(err);
                }
            },
            'SOFTWARE_TOKEN_MFA'
        );
    });
};

// Function to manually restore session from tokens
export const restoreSession = (cognitoUser) => {
    console.log("cognitoUser ", cognitoUser)
    const accessToken = new CognitoAccessToken({
        AccessToken: localStorage.getItem('accessToken')
    });
    const idToken = new CognitoIdToken({
        IdToken: localStorage.getItem('idToken')
    });
    const refreshToken = new CognitoRefreshToken({
        RefreshToken: localStorage.getItem('refreshToken')
    });

    const sessionData = {
        IdToken: idToken,
        AccessToken: accessToken,
        RefreshToken: refreshToken,
    };

    const userSession = new CognitoUserSession(sessionData);
    console.log("userSession ", userSession)
    cognitoUser.setSignInUserSession(userSession);
};

// Function to refresh session if the tokens have expired
export const refreshSession = (cognitoUser) => {
    return new Promise((resolve, reject) => {
        const refreshToken = new CognitoRefreshToken({
            RefreshToken: localStorage.getItem('refreshToken')
        });

        cognitoUser.refreshSession(refreshToken, (err, session) => {
            if (err) {
                console.error('Error refreshing session:', err);
                reject(err);
            } else {
                console.log('Session refreshed:', session);
                localStorage.setItem('accessToken', session.getAccessToken().getJwtToken());
                localStorage.setItem('idToken', session.getIdToken().getJwtToken());
                resolve(session);
            }
        });
    });
};