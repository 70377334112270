import { configureStore } from '@reduxjs/toolkit'
import dataSlice from './dataSlice';
import bannerSlice from './bannerSlice';
import logoSlice from './logoSlice';
import logger from 'redux-logger';

export default configureStore(
  {
    reducer: {
      data: dataSlice,
      banner: bannerSlice,
      logo: logoSlice
    },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(logger)
  })