import React from "react";
import { Menu, MenuItem, ListItemIcon, ListItemText } from "@mui/material";
import PersonRoundedIcon from "@mui/icons-material/PersonRounded";
import LogoutIcon from "@mui/icons-material/Logout";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import { useNavigate, useLocation } from "react-router-dom";
import ProfileUpdate from "../Pages/ProfileUpdate";
import "./Components.css";

const Header = () => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [openProfileDialog, setOpenProfileDialog] = React.useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const open = Boolean(anchorEl);

  const handleClick = (event) => setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);

  const handleLogout = () => {
    handleClose();
    localStorage.clear();
    navigate("/login");
  };

  const handleProfile = () => {
    setOpenProfileDialog(true);
    handleClose();
  };

  const isAuthenticated = !!localStorage.getItem("user_id");
  const excludedPaths = ["/meeting", "/signin", "/login", "/signup","/left-meeting","/setup-mfa", "/"];

  if (!isAuthenticated || excludedPaths.includes(location.pathname)) {
    return null;
  }

  return (
    <header className="basic-header">
      <div className="header-content">
        <div className="header-right">
          <PersonRoundedIcon
            className="profile-icon"
            onClick={handleClick}
            style={{ cursor: "pointer", fontSize: "2rem", color: "#fff" }}
          />
          <Menu
            id="profile-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
          >
            <MenuItem onClick={handleProfile}>
              <ListItemIcon>
                <ManageAccountsIcon fontSize="small" style={{ color: "#fff" }} />
              </ListItemIcon>
              <ListItemText primary="Manage Profile" />
            </MenuItem>
            <MenuItem onClick={handleLogout}>
              <ListItemIcon>
                <LogoutIcon fontSize="small" style={{ color: "#fff" }} />
              </ListItemIcon>
              <ListItemText primary="Logout" />
            </MenuItem>
          </Menu>
        </div>
      </div>
      <ProfileUpdate open={openProfileDialog} onClose={() => setOpenProfileDialog(false)} />
    </header>
  );
};

export default Header;