import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useNavigate } from "react-router-dom";
import { setItemWithExpiry } from '../utils/localStorage';
import { callback } from '../Services/SchedulesApi';

const SocialMediaYT = () => {
    const [pageParams, setPageParams] = useSearchParams();
    const [code, setCode] = useState('');
    const navigate = useNavigate();
    const [shouldNavigate, setShouldNavigate] = useState(false); // Flag to determine whether navigation is needed

    useEffect(() => {
        const authCode = pageParams.get('code');
        setCode(authCode);

        const getAuthData = async () => {
            try {
                const result = await callback(authCode);

                // Store code in localStorage
                localStorage.setItem('code', authCode);

                if (result?.data?.body?.access_token) {
                    setItemWithExpiry('yt_access_token', result?.data?.body?.access_token, 60);
                    setShouldNavigate(true);
                }
            } catch (error) {
                console.error("Error occurred:", error);
            }
        };

        // Call getAuthData only if code is available
        if (authCode) {
            getAuthData();
        }
    }, [pageParams]);

    useEffect(() => {
        if (shouldNavigate) {
            const previousPage_yt = localStorage.getItem('previousPage_yt') || '/shows';
            navigate(previousPage_yt, { state: { showYTPopup: true } });
        }
    }, [shouldNavigate, navigate]);

    return <div />;
};

export default SocialMediaYT;
