import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";

export const AddButton = styled(Button)`
  border: 1px solid white;
  color: white;
  background: transparent;

  &:hover {
    background: grey;
    color: white;
  }

  &:disabled {
    background: grey;
    color: white;
    opacity: 0.7;
    cursor: not-allowed;
  }
`;

export const CancelButton = styled(Button)`
  color: white;
  background: transparent;

  &:hover {
    background: grey;
    color: white;
  }
`;
