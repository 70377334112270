import React, { useState } from "react";
import { TextField, Button, IconButton, Dialog, DialogTitle, DialogContent, DialogActions } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGoogle, faFacebook, faApple } from "@fortawesome/free-brands-svg-icons";
import { useNavigate } from "react-router-dom";
import { AuthService, confirmSignUp, signUp } from "../Services/AuthService"; // Assume AuthService handles Cognito signup/confirmation
import { handleUserSignup, emailValidation } from "../Services/ShowsApi";
import ErrorModal from "../Components/ErrorModal";
import logo from "./../Assests/dyrect-logo.png";
import "./Pages.css";
import { Tooltip } from "@mui/material"; // Import Tooltip
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons"; // Import FontAwesome Info Circle icon
import CheckIcon from "@mui/icons-material/Check";
import ClearIcon from "@mui/icons-material/Clear";
import { useGoogleLogin } from "@react-oauth/google";
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import { v4 as uuidv4 } from 'uuid';

function Signup() {
  const navigate = useNavigate();
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [confirmationCode, setConfirmationCode] = useState("");
  const [isConfirming, setIsConfirming] = useState(false);
  const [userId, setUserId] = useState("");
  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [popup, setPopup] = useState({ open: false, title: "", message: "" });
  const handlePopupClose = () => setPopup({ open: false, title: "", message: "" });
  // const [passwordValidation, setPasswordValidation] = useState({
  //   minLength: false,
  //   hasNumber: false,
  //   hasSpecialChar: false,
  //   hasUppercase: false,
  //   hasLowercase: false
  // });
  const [passwordValidation, setPasswordValidation] = useState({
    minLength: "",
    hasNumber: "",
    hasSpecialChar: "",
    hasUppercase: "",
    hasLowercase: ""
  });


  // Add the Cognito hosted domain and app client details here
  const cognitoDomain = "authentication.dyrect-dev.ai"; // e.g., myapp.auth.us-east-1.amazoncognito.com
  const clientId = process.env.REACT_APP_CLIENT_ID;
  const redirectUri = "http://localhost:3000/shows"; // Make sure this matches your callback URL in Cognito
  const googleProvider = "Google";

  const handleSignup = async (event) => {
    event.preventDefault();
    if (!firstname.trim() || !lastname.trim() || !username.trim() || !email.trim() || !password.trim() || !confirmPassword.trim()) {
      setPopup({
        open: true,
        message: "All fields are required. Please fill in all fields.",
      });
      return;
    }
    if (password !== confirmPassword) {
      setPopup({
        open: true,
        message: "Passwords do not match. Please try again.",
      });
      return;
    }

    try {
      // const data = await AuthService.signUp(username, email, password);
      const doesEmailExist = await emailValidation(email);
      if (doesEmailExist?.data?.response === 'exist') {
        setPopup({
          open: true,
          message: "This email is already registered. Try logging in.",
        });
        return;
      }
      signUp(username, email, password)
        .then(result => {
          setUserId(result.userSub);
          setIsConfirming(true);
        })
        .catch(err => {
          if (err.code === "UsernameExistsException") {
            setPopup({
              open: true,
              message: "It looks like this username is already taken. Please try a different one",
            });
          } else {
            console.error("Sign-up error:", err);
            setPopup({
              open: true,
              message: err.message,
            });
          }
        });
      // if (data.UserConfirmed === false) {
      //   setIsConfirming(true);
      // } else {
      //   alert("Error signing up: " + data.message);
      // }
    } catch (err) {
      console.error("Signup error:", err);
      setPopup({
        open: true,
        message: err.message,
      });
      // alert("Error signing up: " + err.message);
    }
  };

  const handleConfirmSignup = async (event) => {
    event.preventDefault();
    if (!username.trim() || !confirmationCode.trim()) {
      setPopup({
        open: true,
        message: "Username and confirmation code are required.",
      });
      return;
    }
    try {
      const result = await confirmSignUp(username, confirmationCode);
      const payload = {
        "UserID": userId,
        "UserName": username,
        "Email": email,
        "FirstName": firstname,
        "LastName": lastname
      };
      await handleUserSignup(payload);
      navigate("/login");
    } catch (err) {
      console.error("Confirmation error:", err);
      setPopup({
        open: true,
        message: "An error occurred during confirmation. Please try again later.",
      });
    }
  };

  const handleGoogleSignup = useGoogleLogin({
    flow: "popup", 
    onSuccess: async (tokenResponse) => {
      console.log("Token Response:", tokenResponse);
      try {
        const access_token = await exchangeGoogleCodeForToken(tokenResponse?.code);
        const userInfo = await fetchGoogleUserInfo(access_token);
        if (userInfo?.email === undefined) {
          setPopup({
            open: true,
            message: "Unable to retrieve the email. Please try again.",
          });
          return;
        }
        const payload = {
          "UserID": uuidv4(),
          "UserName": userInfo?.email.split('@')[0],
          "Email": userInfo?.email,
          "FirstName": userInfo?.given_name,
          "LastName": userInfo?.family_name
        };
        const doesEmailExist = await emailValidation(userInfo?.email);
        if (doesEmailExist?.data?.response === 'exist') {
          setPopup({
            open: true,
            message: "This email is already registered. Try logging in.",
          });
          return;
        }
        await handleUserSignup(payload);
        localStorage.setItem('user_id', payload?.UserID);
        navigate("/home");
      } catch (error) {
        console.error("Handle Google Sign up failure:", error);
        setPopup({
          open: true,
          message: error?.message? error.message : 'Unable to Create the User',
        });
      }
    },
    onError: (error) => {
      console.error("Google Login Failed:", error);
    },
  });

  const exchangeGoogleCodeForToken = async (authorizationCode) => {
    const tokenUrl = process.env.REACT_APP_GOOGLE_TOKEN_URL;

    const body = new URLSearchParams({
      code: authorizationCode,
      client_id: process.env.REACT_APP_GOOGLE_OAUTH_CLIENT_ID,
      client_secret: process.env.REACT_APP_GOOGLE_CLIENT_SECRET,
      redirectUri: process.env.REACT_APP_GOOGLE_REDIRECT_URL,
      // redirectUri: "http://localhost:3000",
      grant_type: "authorization_code",
    });

    try {
      const response = await fetch(tokenUrl, {
        method: "POST",
        body: body,
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
      });

      const data = await response.json();

      if (response.ok) {
        console.log("Access Token:", data.access_token);
        console.log("ID Token:", data.id_token); // Optional: You can use the id_token to get user info
        return data.access_token; // You can use this token to make authenticated API requests
      } else {
        throw new Error("Failed to exchange code for access token");
      }
    } catch (error) {
      console.error("Error exchanging code for token:", error);
    }
  };

  const fetchGoogleUserInfo = async (accessToken) => {
    const userInfoUrl = process.env.REACT_APP_GOOGLE_USER_INFO_URL;

    try {
      const response = await fetch(userInfoUrl, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      const userInfo = await response.json();
      console.log("User Info:", userInfo);
      return userInfo;
    } catch (error) {
      console.error("Error fetching user info:", error);
    }
  };

  const responseFacebook = async (response) => {
    console.log('Facebook login response:', response);

    if (response.accessToken) {
      console.log('Access Token:', response?.accessToken);
      console.log('User ID:', response?.userID);
      console.log('Name:', response?.name);
      console.log('Email:', response?.email);
      console.log('Picture:', response.picture?.data?.url);
      if (response?.email === undefined) {
        setPopup({
          open: true,
          message: "Unable to retrieve the email. Please try again.",
        });
        return;
      }
      const payload = {
        "UserID": uuidv4(),
        "UserName": response?.email.split('@')[0],
        "Email": response?.email,
        "FirstName": response?.first_name,
        "LastName": response?.last_name
      };
      const doesEmailExist = await emailValidation(response?.email);
      if (doesEmailExist?.data?.response === 'exist') {
        setPopup({
          open: true,
          message: "This email is already registered. Try logging in.",
        });
        return;
      }
      await handleUserSignup(payload);
      localStorage.setItem('user_id', payload?.UserID);
      navigate("/shows");
    } else {
      console.error('Facebook login failed');
    }
  };


  const isPasswordValid = () => {
    const { minLength, hasNumber, hasSpecialChar, hasUppercase, hasLowercase } = passwordValidation;
    return minLength && hasNumber && hasSpecialChar && hasUppercase && hasLowercase;
  };

  const validatePassword = (password) => {
    setPasswordValidation({
      minLength: password.length >= 8,
      hasNumber: /\d/.test(password),
      hasSpecialChar: /[!@#$%^&*]/.test(password),
      hasUppercase: /[A-Z]/.test(password),
      hasLowercase: /[a-z]/.test(password)
    });
  };

  const renderValidationIcon = (isValid) => {
    if(isValid != ""){
      return isValid ? <CheckIcon style={{ color: 'green' }} /> : <ClearIcon style={{ color: 'red' }} />;
    } 
  };

  const login = () => {
    navigate("/login");
  };

  return (
    <div className="height-100 clr-white">
      <div className="container-fluid">
        <div className="row theme-bg">
          <div className="col-xl-7 col-lg-6">
            <div className="logo-container">
              <img src={logo} alt="Dyrect Logo" className="logo-image" />
              <span className="logo-text">yrect</span>
            </div>
            <div className="welcome-grid height-100">Unlock the Experience!</div>
            <div className="ellipse" />
          </div>
          <div className="col-xl-5 col-lg-6" style={{ zIndex: "1000" }}>
            <div className="login-grid m-5">
              <h2 className="login-title">{isConfirming ? "Confirm Sign-Up" : "Sign-Up"}</h2>
              <p className="login-subtitle">
                {isConfirming
                  ? "Enter the confirmation code sent to your email."
                  : "Just some details to get you in!"}
              </p>

              {isConfirming ? (
                <>
                  <TextField
                    label="Username"
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    className="input-label input-field"
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                  />
                  <TextField
                    label="Confirmation Code"
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    className="input-label input-field"
                    value={confirmationCode}
                    onChange={(e) => setConfirmationCode(e.target.value)}
                  />
                  <Button
                    variant="contained"
                    color="primary"
                    fullWidth
                    className="login-button"
                    onClick={handleConfirmSignup}
                  >
                    Confirm Sign-Up
                  </Button>
                </>
              ) : (
                <div>
                  <TextField
                    label="First Name"
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    className="input-label input-field"
                    value={firstname}
                    onChange={(e) => setFirstname(e.target.value)}
                  />
                  <TextField
                    label="Last Name"
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    className="input-label input-field"
                    value={lastname}
                    onChange={(e) => setLastname(e.target.value)}
                  />
                  <TextField
                    label="Username"
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    className="input-label input-field"
                    onChange={(e) => setUsername(e.target.value)}
                  />
                  <TextField
                    label="Email"
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    className="input-label input-field"
                    onChange={(e) => setEmail(e.target.value)}
                  />
                  <TextField
                    label="Password"
                    type="password"
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    className="input-label input-field"
                    value={password}
                    onChange={(e) => {
                      setPassword(e.target.value);
                      validatePassword(e.target.value); // Call validatePassword function here
                    }}
                    InputProps={{
                      endAdornment: (
                        <Tooltip title="Create a strong password with a mix of letters, numbers and symbols">
                          <IconButton>
                            <FontAwesomeIcon icon={faInfoCircle} style={{ color: "#6c757d" }} />
                          </IconButton>
                        </Tooltip>
                      ),
                    }}
                  />

                  <TextField
                    label="Confirm Password"
                    type="password"
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    className="input-label input-field"
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    helperText={
                      <div className="invoice-popup-container mt-3">
                        <h6>Password requirements</h6>
                        <div>
                          {renderValidationIcon(passwordValidation.minLength)} Minimum length (8 characters) <br />
                          {renderValidationIcon(passwordValidation.hasNumber)} Contains at least 1 number <br />
                          {renderValidationIcon(passwordValidation.hasSpecialChar)} Contains at least 1 special character <br />
                          {renderValidationIcon(passwordValidation.hasUppercase)} Contains at least 1 uppercase letter <br />
                          {renderValidationIcon(passwordValidation.hasLowercase)} Contains at least 1 lowercase letter
                        </div>
                      </div>
                    }
                  />
                  <Button
                    variant="contained"
                    color="primary"
                    fullWidth
                    className="login-button"
                    onClick={handleSignup}
                    disabled={!isPasswordValid()}
                  >
                    Sign Up
                  </Button>
                </div>
              )}

              <div className="or-section">
                <span>Or</span>
              </div>
              <div className="social-icons">
                <IconButton aria-label="Google" className="social-icon google" onClick={handleGoogleSignup}>
                  <FontAwesomeIcon icon={faGoogle} style={{ color: "#db4437", fontSize: "1.5em" }} />
                </IconButton>
                <FacebookLogin
                  appId={process.env.REACT_APP_FACEBOOK_APP_ID}
                  autoLoad={false}
                  fields="gender,birthday"
                  callback={responseFacebook}
                  disableMobileRedirect={true}
                  render={(renderProps) => (
                    <IconButton
                      aria-label="Facebook"
                      className="social-icon facebook"
                      onClick={renderProps.onClick}
                      disabled={renderProps.isDisabled}
                    >
                      <FontAwesomeIcon icon={faFacebook} style={{ color: "#4267B2", fontSize: "1.5em" }} />
                    </IconButton>
                  )}
                />
                <IconButton aria-label="Apple" className="social-icon apple" onClick={() => alert("Apple clicked")}>
                  <FontAwesomeIcon icon={faApple} style={{ color: "#ffffff", fontSize: "1.5em" }} />
                </IconButton>
              </div>
              <p className="signup-text">
                Already registered? <span className="links" onClick={login}>Login</span>
              </p>
              <div className="footer-links">
                <a href="javascript:void(0)">Terms & Conditions</a> <a href="javascript:void(0)">Support</a>{" "}
                <a href="javascript:void(0)">Customer Care</a>
              </div>
            </div>
          </div>
          <div className="bottom-ellipse"></div>
        </div>
        <ErrorModal
          open={popup.open}
          title={popup.title}
          message={popup.message}
          onClose={handlePopupClose}
        />
      </div>
    </div>
  );
}

export default Signup;
