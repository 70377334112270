import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    x: null, y: 88
}

const bannerSlice = createSlice({
    name: 'banner',
    initialState,
    reducers: {
        setBannerPosition: (state, action) => {
            state.x = action.payload.x;
            state.y = action.payload.y;
        }
    }
});
export const { setBannerPosition } = bannerSlice.actions;
export default bannerSlice.reducer;