import React, { useState, useEffect, useRef } from "react";
import { Box, Typography, Button } from "@mui/material";
import { useMeeting, usePubSub } from "@videosdk.live/react-sdk";
import axios from 'axios';
import { createLogo, createMeetingLogo, getLogos, updateMeetingLogo, deleteLogo } from "../Services/BannersApi";  // Include the deleteLogo function
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setLogoFile } from "../redux/logoSlice";

const LogosPanel = () => {
    const { publish } = usePubSub("UPDATE_LOGO");
    const mMeeting = useMeeting();
    const dispatch = useDispatch();
    const logoFile = useSelector((state) => state?.logo);

    const [logos, setLogos] = useState([]); // To store the logos
    const [newLogo, setNewLogo] = useState(null); // Stores the image file
    const [isCreatingLogo, setIsCreatingLogo] = useState(false); // Flag for logo creation
    const [selectedLogo, setSelectedLogo] = useState(null); // Selected logo
    const [hoveredLogo, setHoveredLogo] = useState(null); // Hovered logo
    const fileInputRef = useRef(null);
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const meetingId = queryParams.get("meetingId");

    useEffect(() => {

        fetchLogos();
    }, []); // Empty dependency array to run only once when the component is mounted

    // Fetch logos on mount
    const fetchLogos = async () => {
        try {
            const response = await getLogos();
            setLogos(response?.Data); // Update the logos state with the fetched logos

            // Get the selected logo from localStorage if available
            const selectedLogoId = localStorage.getItem('selectedLogoId');
            if (selectedLogoId) {
                const logo = response?.Data?.find(logo => logo.LogoID === selectedLogoId);
                setSelectedLogo(logo); // Set the selected logo if found
            }
        } catch (error) {
            console.error('Error fetching logos:', error);
        }
    };
    const handleLogoClick = async (logo) => {
        // Toggle the selection of the logo
        if (selectedLogo === logo) {
            console.log("condition1")
            setSelectedLogo(null);
            publish({ LogoId: null, LogoUrl: null, visibility: false }, { persist: true });
            localStorage.removeItem('selectedLogoId'); // Remove selected logo from localStorage
            if (mMeeting?.isRecording) {
                const updateMeetingLogoData = {
                    LogoID: logo.LogoID,
                    MeetingID: meetingId,
                    EndTime: getCurrentTimestamp()
                };
                await updateMeetingLogo(updateMeetingLogoData);
            }
        } else {
            console.log("condition2")
            setSelectedLogo(logo);
            publish({ LogoId: logo.LogoID, LogoUrl: logo.LogoUrl, visibility: true, LogoPositionX: logo.LogoPositionX, LogoPositionY: logo.LogoPositionY, }, { persist: true });
            localStorage.setItem('selectedLogoId', logo.LogoID); // Save selected logo's ID to localStorage
            if (mMeeting?.isRecording) {
                console.log("here")
                const createMeetingLogoData = {
                    LogoID: logo.LogoID,
                    MeetingID: meetingId,
                    StartTime: getCurrentTimestamp(),
                    LogoPositionX: logo.LogoPositionX,
                    LogoPositionY: logo.LogoPositionY
                };
                await createMeetingLogo(createMeetingLogoData);
            }
        }
    };

    const triggerFileInput = () => {
        if (fileInputRef.current) {
            fileInputRef.current.click(); // Trigger file input click
        }
    };

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            setNewLogo(file);
            setIsCreatingLogo(true); // Start logo creation process automatically
            dispatch(setLogoFile(file))
        }
    };

    // Convert image file to base64
    const convertToBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onloadend = () => resolve(reader.result); // Resolve with base64 data
            reader.onerror = (error) => reject(error); // Reject if there's an error
        });
    };

    // Handle saving the logo after base64 conversion and POST request
    const handleSaveLogo = async () => {
        if (newLogo) {
            try {
                // Convert the logo to base64
                const base64Image = await convertToBase64(newLogo);
                let prefix = "data:image/png;base64,";
                const logoData = {
                    UserID: localStorage.getItem('user_id'),
                    LogoBase64: base64Image.substring(
                        prefix.length
                    ),
                    LogoPositionX: logoFile.x,
                    LogoPositionY: logoFile.y
                };

                // Make the POST request to create the logo
                const response = await createLogo(logoData);

                // On success, fetch the updated list of logos from the GET API
                if (response && response.data) {
                    const logosResponse = await getLogos();
                    console.log("logosResponse", logosResponse?.Data)
                    setLogos(logosResponse?.Data); // Update logos state with the new list
                }

                setNewLogo(null); // Reset the new logo state
                setIsCreatingLogo(false); // Reset the creating state
            } catch (error) {
                console.error("Error saving logo:", error);
            }
        }
    };

    const handleDeleteLogo = async (logo) => {
        try {
            // Call the delete API function
            if (selectedLogo === logo) {
                setSelectedLogo(null);
                publish({ LogoId: null, LogoUrl: null, visibility: false }, { persist: true });
            }
            const response = await deleteLogo(logo.LogoID);
            fetchLogos();

        } catch (error) {
            console.error("Error deleting logo:", error);
        }
    };

    function getCurrentTimestamp() {
        // const now = new Date();
        // const year = now.getFullYear();
        // const month = String(now.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed
        // const day = String(now.getDate()).padStart(2, '0');
        // const hours = String(now.getHours()).padStart(2, '0');
        // const minutes = String(now.getMinutes()).padStart(2, '0');
        // const seconds = String(now.getSeconds()).padStart(2, '0');

        // return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
        
        //sending UTC time instaed of IST
        return new Date().toISOString();
    }

    return (
        <Box color="white" className="px-3">
            <div style={{ minHeight: "55vh", maxHeight: "55vh", overflowY: "auto" }}>
                <div className="my-2">
                    <Typography
                        variant="h6"
                        onClick={triggerFileInput} // Trigger file input click
                        style={{
                            cursor: 'pointer',
                            width: '100%',
                            textAlignLast: 'center',
                            padding: '2%',
                            borderRadius: '10px',
                        }}
                        className="schedule-btn"
                    >
                        Add a New Logo
                    </Typography>
                </div>

                {/* File input for selecting the logo */}
                <input
                    type="file"
                    ref={fileInputRef}
                    style={{ display: "none" }}
                    accept="image/png"
                    onChange={handleFileChange}
                />

                {/* Preview and Save button */}
                {isCreatingLogo && newLogo && (
                    <div style={{ marginTop: "20px", textAlign: "center" }}>
                        <img
                            src={URL.createObjectURL(newLogo)} // Preview the selected image
                            alt="Logo Preview"
                            style={{ width: "3em", height: "3em", objectFit: "contain" }}
                        />
                        <div>
                            <Button variant="contained" color="primary" onClick={handleSaveLogo}>
                                Save Logo
                            </Button>
                        </div>
                    </div>
                )}

                {/* Display logos in two columns per row */}
                <div style={{ display: "flex", flexWrap: "wrap", gap: "10px" }}>
                    {logos?.map((logo, index) => (
                        <div
                            key={index}
                            className="logoData pointer"
                            onMouseEnter={() => setHoveredLogo(logo)}
                            onMouseLeave={() => setHoveredLogo(null)}
                            onClick={() => handleLogoClick(logo)} // Handle logo selection
                            style={{
                                position: "relative",
                                width: "45%", // Display two logos side by side
                                marginBottom: "10px",
                                backgroundColor: selectedLogo === logo ? '#ffffff50' : hoveredLogo === logo ? '#ffffff30' : 'transparent',
                            }}
                        >
                            <img
                                src={logo.LogoUrl} // If URL, use the logo URL, else create a URL from the uploaded file
                                alt={`Logo ${index}`}
                                style={{ width: "100%", height: "100px", objectFit: "contain" }}
                            />
                            {/* The buttons are visible when hovered */}
                            <div
                                style={{
                                    position: 'absolute',
                                    top: '50%', // Vertically center the button
                                    left: '50%', // Horizontally center the button
                                    transform: 'translate(-50%, -50%)', // Adjust both x and y positioning to center it perfectly
                                    backgroundColor: 'rgba(0, 0, 0, 0.6)',
                                    color: 'white',
                                    padding: '5px 10px',
                                    borderRadius: '5px',
                                    cursor: 'pointer',
                                    visibility: selectedLogo === logo || hoveredLogo === logo ? 'visible' : 'hidden', // Show when hovered or selected
                                }}
                            >
                                <div
                                    onClick={(e) => {
                                        e.stopPropagation(); // Prevent the logo selection handler from being triggered
                                        handleLogoClick(logo);
                                    }}
                                    style={{ padding: '5px 10px' }}
                                >
                                    {selectedLogo === logo ? 'Hide' : 'Show'}
                                </div>
                                <div
                                    onClick={(e) => {
                                        e.stopPropagation(); // Prevent the logo selection handler from being triggered
                                        handleDeleteLogo(logo); // Call delete logo function
                                    }}
                                    style={{ padding: '5px 10px', backgroundColor: 'red', borderRadius: '5px' }}
                                >
                                    Delete
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </Box>
    );
};

export default LogosPanel;
