import React from 'react'
import SideBar from '../../Components/Sidebar'
import HomepageTop from '../Container/HomePage/HomepageTop'
import HomepageContainer from '../Container/HomePage/HomepageContainer'
import './Homepage.css'

const HomePage = () => {
    return (
        <div className='theme-bg clr-white height-100' style={({ display: "flex" })}>
            <SideBar></SideBar>
            <div className='container overflow-x-none'>
                <div className='row'>
                    <div className="container" style={{ minHeight: "100vh", color: "#fff" }}>
                        <div className="row pt-5">
                            <HomepageTop></HomepageTop>
                            <div className="row justify-content-center" style={{ height: '80vh', overflowY: 'auto' }}>
                                <div className="col-md-12">
                                    <HomepageContainer></HomepageContainer>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <div className='container-fluid overflow-x-none'>
                <HomepageTop />
                <div className="container-fluid text-white">
                    <HomepageContainer />
                </div>
            </div> */}
        </div >
    )
}

export default HomePage
