import React, { useEffect, useState } from 'react';
import {
    Dialog, DialogActions, DialogContent, DialogTitle, TextField, Button,
    MenuItem, Select, InputLabel, FormControl, Tooltip, Typography, Popper, Chip
} from '@mui/material';
import { HelpOutline } from '@mui/icons-material';
import { uploadToYT } from '../Services/SchedulesApi';
import SuccessModal from '../Components/SuccessModal';
import ErrorModal from '../Components/ErrorModal';

const YTPopup = ({ data, closePopup }) => {
    const [isDialogOpen, setIsDialogOpen] = useState(true);
    const [title, setTitle] = useState(data?.ShowName || "");
    const [description, setDescription] = useState(data?.summary || "");
    const [newTag, setNewTag] = useState('');
    const [tags, setTags] = useState(data?.Tags || []);

    // Success modal state
    const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);
    const [successMessage, setSuccessMessage] = useState("");
    const [videoUrl, setVideoUrl] = useState("");

    // Error modal state
    const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [selectedVideo, setSelectedVideo] = useState({
        type: data?.ShowURL ? 'full' : '', 
        url: data?.ShowURL || ''
    });    
    const [isUploadEnabled, setIsUploadEnabled] = useState(false);
    const [durationWarning, setDurationWarning] = useState("");

    useEffect(() => {
        setIsUploadEnabled(!!selectedVideo?.url && !!title); // Enable if both video and title are filled
    }, [selectedVideo?.url, title]);    

    const closeDialog = () => {
        setIsDialogOpen(false);
    };

    const extractBucketAndKey = (url) => {
        try {
            const parsedUrl = new URL(url);
            const bucketName = parsedUrl.hostname.split(".")[0];
            const path = parsedUrl.pathname;
            const keyName = path.startsWith("/") ? path.slice(1) : path;

            return { bucketName, keyName };
        } catch (error) {
            console.error("Invalid URL:", error);
            return { bucketName: null, keyName: null };
        }
    };
    const handleVideoSelection = (video) => {
        console.log(video);
        setSelectedVideo(video);
        const videoDuration =
            video.type === 'full'
                ? data?.RunTime ?? 0 
                : data?.PromoVideos?.find((vid) => vid.url === video.url)?.duration ?? 0; 
        console.log("video duration:", videoDuration);
    
        // Check if the duration exceeds 15 minutes (900 seconds)
        if (videoDuration && videoDuration > 900) {
            setDurationWarning(
                "This video exceeds 15 minutes. To ensure proper uploading, please verify your YouTube channel. For detailed instructions, watch this " +
                `<a href="https://youtu.be/L2BZQlnlc5M?feature=shared" target="_blank" style="color: #0985FB;">walkthrough video</a>.`
            );
        } else {
            setDurationWarning("");
        }
    };

    const handleUpload = async () => {
        console.log("selected Video",selectedVideo);
        const finalTitle = title || data?.ShowName;
        const finalDescription = description || data?.summary;
        const { bucketName, keyName } = extractBucketAndKey(selectedVideo?.url || data?.ShowURL);

        if (!bucketName || !keyName) {
            alert("Invalid URL provided for the video file.");
            return;
        }

        const payload = {
            accessToken: JSON.parse(localStorage.getItem('yt_access_token')).value,
            title: finalTitle,
            description: finalDescription,
            bucketName,
            objectKey: keyName,
            showID: data?.ShowID || '',
            userID: localStorage.getItem("user_id"),
            tags
        };
        console.log("payload", payload);
        try {
            const response = await uploadToYT(payload);
            if (response?.data?.statusCode == 200) {
                // setVideoUrl(response.data.body.videoUrl);
                setSuccessMessage("Video uploaded successfully!");
                setIsSuccessModalOpen(true);
            } else {
                setErrorMessage("An error occurred while uploading the video. Please try again later.");
                setIsErrorModalOpen(true); // Open error modal
            }
        } catch (error) {
            console.error("Error uploading video:", error);
            setErrorMessage("An error occurred while uploading the video. Please try again later. ");
            setIsErrorModalOpen(true); // Open error modal
        }
        closeDialog();
    };

    useEffect(() => {
        setTitle(data?.ShowName || '');
        setDescription(data?.summary || '');
        setTags(data?.Tags || []);
    }, [data]);
    const handleNewTagChange = (e) => {
        setNewTag(e.target.value);
    };
    const handleAddTag = (e) => {
        if (e.key === 'Enter' && newTag.trim()) {
            setTags((prevTags) => [...prevTags, newTag.trim()]);
            setNewTag('');
        }
    };

    const handleDeleteTag = (tagToDelete) => {
        setTags((prevTags) => prevTags.filter((tag) => tag !== tagToDelete));
    };

    return (
        <div>
            <Dialog
                open={isDialogOpen}
                onClose={closePopup}
                fullWidth
                sx={{
                    '& .MuiPaper-root': {
                        backgroundColor: 'var(--theme-bg)',
                        color: 'white',
                    },
                }}
            >
                <DialogTitle>Video Details</DialogTitle>
                <DialogContent>
    <FormControl
        variant="filled"
        fullWidth
        margin="normal"
        sx={{
            bgcolor: 'rgba(255, 255, 255, 0.1)',
            borderRadius: 1,
            '.MuiInputLabel-root': {
                color: 'white',
            },
            '.Mui-focused .MuiInputLabel-root': {
                color: 'white',
            },
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            gap: '8px',
        }}
    >
        <InputLabel sx={{ color: 'white' }}>
            Select Video<span>*</span>
        </InputLabel>
        <Select
            value={selectedVideo ? JSON.stringify(selectedVideo) : ''}
            onChange={(e) => handleVideoSelection(JSON.parse(e.target.value))}
            sx={{
                color: 'white',
                '.MuiSelect-icon': { color: 'white' },
                '&:focus': {
                    backgroundColor: 'transparent',
                },
                flex: 1,
            }}
            MenuProps={{
                PaperProps: {
                    style: {
                        maxHeight: 150,
                        width: 250,
                    },
                },
            }}
        >
            {/* Full Video */}
            <MenuItem value={JSON.stringify({ type: 'full', url: data?.ShowURL })}>
                Full Video
            </MenuItem>

            {/* Promo Videos */}
            {data?.PromoVideos?.length > 0 ? (
                data.PromoVideos.map((promo, index) => (
                    <MenuItem key={index} value={JSON.stringify({ type: 'promo', url: promo.url })}>
                        Promo Video {index + 1}
                    </MenuItem>
                ))
            ) : (
                <MenuItem disabled>No promo videos available</MenuItem>
            )}
        </Select>
    </FormControl>

    {/* Warning Banner below the dropdown */}
    {selectedVideo && durationWarning && (
    <div
        style={{
            backgroundColor: '#ffcc00', // Slightly softer yellow for the background
            color: '#333', // Darker text for better contrast
            padding: '15px', // Added some space around the message
            borderRadius: '8px', // Rounded corners for a modern look
            marginTop: '15px',
            fontWeight: 'bold',
            fontSize: '14px', // Slightly larger font for better readability
            display: 'flex',
            alignItems: 'center', // Center the content vertically
            gap: '10px', // Space between the icon and the text
        }}
    >
        <HelpOutline style={{ color: '#ff9800' }} /> {/* Icon color matching the background */}
        <span
            dangerouslySetInnerHTML={{
                __html: durationWarning, // Display the warning message with HTML content
            }}
        />
    </div>
)}

    <TextField
        label="Title"
        variant="filled"
        InputProps={{ style: { color: 'white' } }}
        InputLabelProps={{ style: { color: 'white' } }}
        sx={{ bgcolor: 'rgba(255, 255, 255, 0.1)', borderRadius: 1 }}
        fullWidth
        margin="normal"
        value={title}
        onChange={(e) => setTitle(e.target.value)}
        required
    />
    <TextField
        label="Description"
        variant="filled"
        InputProps={{
            style: { color: 'white' },
        }}
        InputLabelProps={{
            style: { color: 'white' },
        }}
        sx={{
            bgcolor: 'rgba(255, 255, 255, 0.1)',
            borderRadius: 1,
        }}
        fullWidth
        margin="normal"
        multiline
        rows={6}
        value={description}
        onChange={(e) => setDescription(e.target.value)}
    />
    <TextField
        fullWidth
        label="Tags"
        value={newTag}
        onChange={(e) => setNewTag(e.target.value)}
        onKeyPress={handleAddTag} // Listen for Enter key
        margin="normal"
        variant="filled"
        sx={{
            bgcolor: 'rgba(255, 255, 255, 0.1)',
            borderRadius: 1,
        }}
    />
    <div style={{ marginTop: '10px' }}>
        {tags.map((tag, index) => (
            <Chip
                key={index}
                label={tag}
                onDelete={() => handleDeleteTag(tag)} // Deleting a tag
                style={{ margin: '5px' }}
            />
        ))}
    </div>
</DialogContent>

                <DialogActions>
                    <Button onClick={closeDialog} sx={{ color: 'white' }}>Cancel</Button>
                    <Button
                        onClick={handleUpload}
                        sx={{
                            bgcolor: !isUploadEnabled
                                ? '#B0B0B0'
                                : 'linear-gradient(to right, #472DDB, #0985FB, #00D7FD)',
                            color: 'white',
                            '&:hover': {
                                bgcolor: !isUploadEnabled ? '#B0B0B0' : 'linear-gradient(to right, #472DDB, #0985FB, #00D7FD)',
                            },
                            border: '1px solid white',
                        }}
                        disabled={!isUploadEnabled}
                    >
                        Upload
                    </Button>
                </DialogActions>
            </Dialog>
            <SuccessModal
                open={isSuccessModalOpen}
                message={
                    <>
                        The video is currently being published and will be available to view on your YouTube channel in just a few minutes
                    </>
                }
                onClose={() => setIsSuccessModalOpen(false)}
            />
            <ErrorModal
                open={isErrorModalOpen}
                message={errorMessage}
                onClose={() => setIsErrorModalOpen(false)}
            />
        </div>
    );
};

export default YTPopup;