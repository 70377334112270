import React, { useState, useEffect } from "react";
import { Box, Divider, Slider, Typography, Button, TextField, IconButton } from "@mui/material";
import { useMeeting, usePubSub } from "@videosdk.live/react-sdk";
import DeleteIcon from '@mui/icons-material/Delete';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { createBanner, createMeetingBanner, deleteBanner, listBanners, updateMeetingBanner } from "../Services/BannersApi";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";

const BannerPanel = ({ meeting }) => {
  const { publish } = usePubSub("UPDATE_BANNER");
  const mMeeting = useMeeting();
  const savedBgColor = localStorage.getItem('bgColor') || "#00002910";
  const savedBannerText = localStorage.getItem('bannerText') || "This is a sample banner.";
  const savedFontSize = Number(localStorage.getItem('fontSize')) || 16;

  const [bgColor, setBgColor] = useState(savedBgColor);
  const [bannerText, setBannerText] = useState(savedBannerText);
  const [fontSize, setFontSize] = useState(savedFontSize);
  const [bannerData, setBannerData] = useState([]);  // Start with an empty list
  const [newBanner, setNewBanner] = useState('');
  const [selectedBanner, setSelectedBanner] = useState(null);
  const [isCreatingBanner, setIsCreatingBanner] = useState(false);
  const [hoveredBanner, setHoveredBanner] = useState(null);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const meetingId = queryParams.get("meetingId");
  const banner = useSelector((state) => state?.banner);

  // Load banners when component mounts
  useEffect(() => {
    fetchBanners();
  }, []);


  // Save state to localStorage
  useEffect(() => {
    localStorage.setItem('bgColor', bgColor);
    localStorage.setItem('bannerText', bannerText);
    localStorage.setItem('fontSize', fontSize);
  }, [bgColor, bannerText, fontSize]);

  const handleBannerClick = async (banner) => {
    if (mMeeting?.isRecording) {
      const createBannerData = {
        BannerID: banner?.BannerID,
        MeetingID: meetingId,
        BackgroundColor: bgColor,
        FontSize: `${fontSize}px`,
        StartTime: getCurrentTimestamp()
      };
      console.log('createBannerData', createBannerData);
      await createMeetingBanner(createBannerData)
    }
    setSelectedBanner(banner);
    setBannerText(banner.BannerText);  // Access BannerText to update state
    const bannerPayload = {
      BannerID: banner?.BannerID,
      message: banner.BannerText,  // Send only the BannerText to the publish function
      backgroundColor: bgColor,
      fontSize: `${fontSize}px`,
    };
    publish(bannerPayload, { persist: true });
  };

  function getCurrentTimestamp() {
    // const now = new Date();
    // const year = now.getFullYear();
    // const month = String(now.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed
    // const day = String(now.getDate()).padStart(2, '0');
    // const hours = String(now.getHours()).padStart(2, '0');
    // const minutes = String(now.getMinutes()).padStart(2, '0');
    // const seconds = String(now.getSeconds()).padStart(2, '0');

    // return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    //sending UTC time instaed of IST
    return new Date().toISOString();
  }

  const fetchBanners = async () => {
    try {
      const response = await listBanners(localStorage.getItem('user_id'));
      if (response) {
        setBannerData(response.data.Data);
      } else {
        setBannerData([]);  // Fallback to empty array if data is not valid
      }
    } catch (error) {
      console.error("Error fetching banners:", error);
      setBannerData([]); // Fallback to empty array on error
    }
  };

  const handleColorChange = async (event) => {
    const selectedColor = event.target.value;
    setBgColor(selectedColor);
    if (mMeeting?.isRecording) {
      const createBannerData = {
        BannerID: selectedBanner?.BannerID,
        MeetingID: meetingId,
        BackgroundColor: selectedColor,
        FontSize: `${fontSize}px`,
        StartTime: getCurrentTimestamp()
      };
      console.log('createBannerData', createBannerData);
      console.log("CallingBannerAPICC")
      await createMeetingBanner(createBannerData)
    }
    if (selectedBanner) {
      const bannerPayload = {
        BannerID: selectedBanner?.BannerID,
        message: selectedBanner.BannerText,  // Only pass BannerText
        backgroundColor: selectedColor,
        fontSize: `${fontSize}px`,
      };
      console.log("CallingBannerAPICC")
      publish(bannerPayload, { persist: true });
    }
  };

  const handleFontSizeChange = async (event, newValue) => {
    setFontSize(newValue);
    if (mMeeting?.isRecording) {
      console.log("CallingBannerAPIFF")
      const createBannerData = {
        BannerID: selectedBanner?.BannerID,
        MeetingID: meetingId,
        BackgroundColor: bgColor,
        FontSize: `${newValue}px`,
        StartTime: getCurrentTimestamp(),
        bannerPositionX: banner.x,
        bannerPositionY: banner.y
      };
      console.log('createBannerData', createBannerData);
      await createMeetingBanner(createBannerData)
    }
    if (selectedBanner) {
      const bannerPayload = {
        BannerID: selectedBanner?.BannerID,
        message: selectedBanner.BannerText,  // Only pass BannerText
        backgroundColor: bgColor,
        fontSize: `${newValue}px`,
        bannerPositionX: banner.x,
        bannerPositionY: banner.y
      };
      console.log("CallingBannerAPIFF")
      publish(bannerPayload, { persist: true });
    }
  };

  const handleAddNewBanner = async () => {
    if (newBanner.trim()) {
      const bannerDataToAdd = {
        UserID: localStorage.getItem('user_id'), // Use actual UserID
        BannerText: newBanner.trim(),
      };

      const response = await createBanner(bannerDataToAdd); // API call to create banner
      if (response && response.data) {
        // Ensure bannerData is an array before updating it
        fetchBanners();
        setNewBanner('');
        setIsCreatingBanner(false);
      }
    }
  };

  const handleDeleteBanner = async (banner) => {
    // First, delete the banner via the API call
    const response = await deleteBanner(banner?.BannerID); // API call to delete banner
    if (mMeeting?.isRecording) {
      const updateBannerData = {
        BannerID: banner?.BannerID,
        MeetingID: meetingId,
        EndTime: getCurrentTimestamp()
      };
      console.log('updateBannerData', updateBannerData);
      await updateMeetingBanner(updateBannerData);
    }
    if (response && response.data) {
      // Remove the banner from the local state
      setBannerData(bannerData.filter(item => item !== banner));

      // If the deleted banner is the selected banner, clear the selection and notify PubSub
      if (selectedBanner === banner) {
        setSelectedBanner(null);

        // Notify PubSub that there is no banner being shown (empty banner payload)
        const bannerPayload = {
          BannerID: null,
          message: '',
          backgroundColor: bgColor,
          fontSize: `${fontSize}px`,
        };
        publish(bannerPayload, { persist: true });
      }
    }
  };

  const handleStopBanner = async () => {
    setSelectedBanner(null);
    if (mMeeting?.isRecording) {
      const updateBannerData = {
        BannerID: selectedBanner?.BannerID,
        MeetingID: meetingId,
        EndTime: getCurrentTimestamp()
      };
      console.log('updateBannerData', updateBannerData);
      await updateMeetingBanner(updateBannerData);
    }
    const bannerPayload = {
      BannerID: null,
      message: '',
      backgroundColor: bgColor,
      fontSize: `${fontSize}px`,
    };
    publish(bannerPayload, { persist: true });
  };

  const toggleBannerVisibility = (banner) => {
    const isCurrentlySelected = selectedBanner === banner;
    if (isCurrentlySelected) {
      handleStopBanner();
    } else {
      handleBannerClick(banner);
    }
  };

  return (
    <Box color="white" className="px-3">
      <div style={{ minHeight: '55vh', maxHeight: '55vh', overflowY: 'auto' }}>
        <div className="my-2">
          {!isCreatingBanner ? (
            <Typography variant="h6" onClick={() => setIsCreatingBanner(true)}
              style={{
                cursor: 'pointer',
                width: '100%',
                textAlignLast: 'center',
                padding: '2%',
                borderRadius: '10px'
              }}
              className="schedule-btn"
            >
              Create a Banner
            </Typography>
          ) : (
            <div>
              <TextField
                label="New Banner"
                variant="filled"
                fullWidth
                value={newBanner}
                onChange={(e) => setNewBanner(e.target.value)}
              />
              <Button
                variant="contained"
                color="primary"
                onClick={handleAddNewBanner}
                style={{ marginTop: '10px', textTransform: 'none' }}
              >
                Add Banner
              </Button>
              <Button
                variant="outlined"
                className="cancel-btn"
                onClick={() => setIsCreatingBanner(false)}
                style={{ marginTop: '10px', textTransform: 'none', marginLeft: '10px' }}
              >
                Cancel
              </Button>
            </div>
          )}
        </div>
        {bannerData?.map((banner, index) => (
          <div
            key={index}
            className="bannerData pointer"
            onMouseEnter={() => setHoveredBanner(banner)}
            onMouseLeave={() => setHoveredBanner(null)}
            style={{
              position: 'relative',
              backgroundColor: selectedBanner === banner ? '#ffffff50' : '#ffffff10',
            }}
          >
            <div
              onClick={() => handleBannerClick(banner)} // Pass the banner to handleBannerClick
              style={{
                color: 'white',
                borderRadius: '5px',
                cursor: 'pointer',
                position: 'relative',
                width: '90%'
              }}
            >
              {banner.BannerText}  {/* Render the BannerText property */}
            </div>
            <IconButton
              onClick={() => handleDeleteBanner(banner)}  // Handle delete of the specific banner
              style={{
                position: 'absolute',
                top: '5px',
                right: '5px',
                color: 'red',
              }}
            >
              <DeleteIcon />
            </IconButton>
            {hoveredBanner === banner && (
              <div
                style={{
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  transform: 'translate(-50%, -50%)',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  backgroundColor: 'rgba(0, 0, 0, 0.6)',
                  color: 'white',
                  padding: '5px 10px',
                  borderRadius: '5px',
                  cursor: 'pointer',
                }}
                onClick={() => toggleBannerVisibility(banner)}  // Toggle visibility
              >
                {selectedBanner === banner ? (
                  <>
                    <VisibilityOffIcon style={{ marginRight: '5px' }} />
                    Hide
                  </>
                ) : (
                  <>
                    <VisibilityIcon style={{ marginRight: '5px' }} />
                    Show
                  </>
                )}
              </div>
            )}
          </div>
        ))}
      </div>

      <Divider style={{ borderColor: 'white' }} className="my-2" />

      <div>
        <label htmlFor="colorPicker" style={{ verticalAlign: 'top' }}>Brand Color: </label>
        <br />
        <input
          id="colorPicker"
          type="color"
          value={bgColor}
          onChange={handleColorChange}
          style={{
            padding: '0',
            border: 'none',
            height: '30px',
            width: '30px',
          }}
        />
      </div>
      <Divider style={{ borderColor: 'white' }} className="my-2" />

      <div>
        <Typography gutterBottom>Font Size</Typography>
        <Slider
          value={fontSize}
          onChange={handleFontSizeChange}
          aria-labelledby="font-size-slider"
          valueLabelDisplay="auto"
          valueLabelFormat={(value) => `${value}px`}
          min={15}
          max={40}
          step={1}
          style={{
            marginLeft: '2%',
          }}
        />
      </div>

      <Divider style={{ borderColor: 'white' }} className="my-2" />
    </Box>
  );
};

export default BannerPanel;
