import React, { createContext, useState, useContext } from 'react';
import SnackBarMessage from './SnackBarMessage';

// Create a Context for the Snackbar
const SnackbarContext = createContext();

// Create a custom hook to use the Snackbar context
export const useSnackbar = () => {
  return useContext(SnackbarContext);
};

// SnackbarProvider component that provides the context
export const SnackbarProvider = ({ children }) => {
  const [isSnackbarOpen, setIsSnackbarOpen] = useState(false);
  const [snackBarMessage, setSnackBarMessage] = useState('');

  // Function to trigger the Snackbar with a message
  const triggerSnackbar = (message) => {
    setSnackBarMessage(message);
    setIsSnackbarOpen(true);
  };

  return (
    <SnackbarContext.Provider value={{ triggerSnackbar }}>
      {children}
      <SnackBarMessage
        isSnackbarOpen={isSnackbarOpen}
        snackBarMessage={snackBarMessage}
        setIsSnackbarOpen={setIsSnackbarOpen}
      />
    </SnackbarContext.Provider>
  );
};
